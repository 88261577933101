<template>
  <div id="users">
    <h1>Пользователи</h1>

    <table class="table bg-light">
      <thead>
        <tr>
          <th>id</th>
          <th>Имя / Email</th>
          <th>Скидка</th>
          <th>Компания</th>
          <th>Роль</th>
          <th>Заметки</th>
          <th></th>
        </tr>
      </thead>
      <tbody style="font-size: 13px">
      <tr v-for="user of getUsers">
        <td>{{ user.id }}</td>
        <td>{{ user.name }}<br>{{ user.email }}</td>
        <td>{{ user.discount }} %</td>
        <td>{{ user.company }}<br>{{ user.inn }}<br>{{ user.address }}<br>{{ user.phone }}</td>
        <td>{{ user.role }}</td>
        <td>{{ user.notes }}</td>
        <td>
          <b-icon icon="pencil-square" class="mx-2 cursor_pointer" variant="success" @click="onShowEditModal(user)"/>
          <b-icon icon="trash" class="cursor_pointer" variant="danger" @click="onDelete(user)"/>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="6">
          <b-button variant="link" class="cursor_pointer p-0" @click="onShowCreateModal">Добавить пользователя</b-button>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'Users',
    computed: {
      ...mapGetters([
        'getUsers',
      ])
    },
    mounted() {
      this.queryUsers()
    },
    methods: {
      ...mapActions([
        'queryUsers',
        'destroyUser',
        'showModal',
      ]),
      onShowCreateModal() {
        this.showModal({modal: 'ModalUser', title: 'Добавление пользователя', size: 'lg', data: {}})
      },
      onShowEditModal(user) {
        this.showModal({modal: 'ModalUser', title: 'Редактирование пользователя', size: 'lg', data: user})
      },
      onDelete(user) {
        this.$bvModal.msgBoxConfirm('Удалить пользователя "' + user.name + '"?')
          .then(value => {
            this.destroyUser(user.id)
          })
      }
    }
  }
</script>

<style scoped>

</style>