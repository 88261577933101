<template>
  <b-col cols="6" md="6" lg="4" xl="3" class="mb-3">
    <b-card bg-variant="dark"
            text-variant="white"
            class="card_product">
      <b-card-text class="pb-3 position-relative">
        <router-link v-if="product.photos.length" :to="'/product/' + product.slug">
          <div class="card_photo" :style="'background-image: url(' + product.photos[0].photo + ')'"></div>
          <div class="position-absolute bg-danger py-1 px-3 font-weight-bold" style="top: 0; opacity: 0.7" v-if="product.new">Новинка</div>
        </router-link>
        <router-link v-else :to="'/product/' + product.slug">
          <div class="card_photo"></div>
        </router-link>
        <div class="p-2">
          <router-link :to="'/product/' + product.slug">{{ product.title }}</router-link>
        </div>
        <div class="card_price mb-2">{{ product.price }} руб.</div>
        <b-button squared variant="outline-danger" @click="addToCart(product)">В корзину</b-button>
      </b-card-text>
    </b-card>
  </b-col>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    name: 'ProductsGridItem',
    props: ['product'],
    methods: {
      ...mapActions([
        'addToCart'
      ]),
    }
  }
</script>

<style lang="scss">
  .card_product {
    border: none;
    .card-body {
      padding: 0;
      a {
        color: #fff;
      }
      .card_photo {
        height: 155px;
        @media (min-width: 576px) {
          height: 240px;
        }
        @media (min-width: 768px) {
          height: 330px;
        }
        @media (min-width: 991.98px) {
          height: 330px;
        }
        @media (min-width: 1200px) {
          height: 250px;
        }
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
      .card_price {
        font-weight: 100;
        font-size: 26px;
      }
      .btn {
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
</style>