<template>
  <div id="ModalProductSupport">
    <b-form-group
        label="Наименование*"
        label-for="input-0">
      <b-form-input
          id="input-0"
          v-model="form.product"
          type="text"
          required
          disabled/>
    </b-form-group>
    <b-form-group
        label="Артикул*"
        label-for="input-1">
      <b-form-input
          id="input-1"
          v-model="form.article"
          type="text"
          required
          disabled/>
    </b-form-group>
    <b-form-group
        label="Ваше имя*"
        label-for="input-22">
      <b-form-input
          id="input-22"
          v-model="form.name"
          type="text"
          required/>
    </b-form-group>
    <b-form-group
        label="Ваш email*"
        label-for="input-2">
      <b-form-input
          id="input-2"
          v-model="form.email"
          type="email"
          required/>
    </b-form-group>
    <b-form-group
        label="Телефон"
        label-for="input-3">
      <b-form-input
          id="input-3"
          v-model="form.phone"
          type="text"
          placeholder="Для консультации по телефону"/>
    </b-form-group>
    <b-form-group
        label="Вопрос"
        label-for="input-4">
      <b-form-textarea
          id="input-4"
          v-model="form.question"
          rows="3"
          max-rows="6"/>
    </b-form-group>
    <b-button variant="primary" @click="onSubmit">Отправить</b-button>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex'

  export default {
    name: 'ModalProductSupport',
    data() {
      return {
        form: {
          article: null,
          product: null,
          name: null,
          email: null,
          phone: null,
          question: null,
        }
      }
    },
    computed: {
      ...mapGetters([
        'getModalData',
      ]),
    },
    mounted() {
      this.form.product = this.getModalData.title
      this.form.article = this.getModalData.article
    },
    methods: {
      ...mapActions([
        'questionProduct',
        'setToast',
      ]),
      ...mapMutations([
        'setTitle'
      ]),
      onSubmit() {
        if (this.form.article && this.form.product && this.form.name && this.form.email && this.form.question) {
          this.questionProduct(this.form)
        } else {
          this.setToast({status: 'warning', message: 'Заполните обязательные поля'})
        }
      }
    }
  }
</script>

<style>

</style>