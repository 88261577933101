import axios from 'axios'

export default {
  state: {
    list: [],
  },
  mutations: {
    setCategories(state, payload) {
      state.list = payload
    },
  },
  actions: {
    queryCategories({dispatch, commit}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'categories')
        .then(res => {
          dispatch('setPreloader', false)
          commit('setCategories', res.data)
        })
    },
    storeCategory({commit, dispatch}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'categories', payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('queryCategories')
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    updateCategory({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'categories/' + payload[0], payload[1])
        .then(res => {
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('queryCategories')
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
    destroyCategory({dispatch, commit}, category_id) {
      dispatch('setPreloader', true)
      axios.delete(process.env.VUE_APP_API_ENDPOINT + 'categories/' + category_id)
        .then(res => {
          dispatch('queryCategories')
          dispatch('setToast', {status: 'success', message: res.data})
          dispatch('setPreloader', false)
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
  },
  getters: {
    getCategories: state => {
      return state.list
    },
    getCategoriesOptions: state => {
      let options = []
      options.push({value: 0, text: 'Нет'})
      if (state.list)
        for (let item of state.list)
          options.push({value: item.id, text: item.name})
      return options
    },
    getCategoriesOptionsAll: state => {
      let options = []
      if (state.list) {
        for (let item of state.list) {
          options.push({value: item.id, text: item.name})
          if (item.childs) {
            for (let child_item of item.childs) {
              options.push({value: child_item.id, text: '- ' + child_item.name})
            }
          }
        }
      }
      return options
    },
  }
}