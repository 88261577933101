import axios from 'axios'

export default {
  state: {
    list: [],
    page: null,
  },
  mutations: {
    setPages(state, payload) {
      state.list = payload
    },
    setPage(state, payload) {
      state.page = payload
    },
  },
  actions: {
    queryPages({dispatch, commit}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'pages')
        .then(res => {
          dispatch('setPreloader', false)
          commit('setPages', res.data)
        })
    },
    queryPage({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'pages/' + payload)
        .then(res => {
          dispatch('setPreloader', false)
          commit('setPage', res.data)
        })
    },
    storePage({commit, dispatch}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'pages', payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('queryPages')
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    updatePage({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'pages/' + payload[0], payload[1])
        .then(res => {
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('queryPages')
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
    destroyPage({dispatch, commit}, page_id) {
      dispatch('setPreloader', true)
      axios.delete(process.env.VUE_APP_API_ENDPOINT + 'pages/' + page_id)
        .then(res => {
          dispatch('queryPages')
          dispatch('setToast', {status: 'success', message: res.data})
          dispatch('setPreloader', false)
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
  },
  getters: {
    getPages: state => {
      return state.list
    },
    getPage: state => {
      return state.page
    },
  }
}