import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'
Vue.use(VueToast, {position: 'bottom', duration: 7000})

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

window.axios = require('axios')
const auth = JSON.parse(localStorage.getItem('vuex'))
if (auth && auth.auth) {
  window.axios.defaults.headers.common = {
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + auth.auth.token
  }
}
// window.axios.interceptors.response.use(function (response) {
//   return response;
// }, function (error) {
//   if (error.response.status == 401) {
//     return store.dispatch('logout');
//   }
//   return Promise.reject(error);
// });

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
