<template>
  <form class="form" @submit.prevent="onSubmit">
    <div class="form-group">
      <label v-if="getModalData.id">Изображение</label>
      <label v-else>Изображение*</label>
      <div class="custom-file">
        <input type="file" class="custom-file-input" id="customFile" ref="image"
               @change="filename = $refs.image.files[0].name">
        <label class="custom-file-label" for="customFile">{{ filename }}</label>
      </div>
      <a :href="getModalData.image" target="_blank" v-if="getModalData.image">Текущее изображение</a>
    </div>
    <div class="form-group">
      <label>Заголовок</label>
      <b-form-input v-model="form.title"/>
    </div>
    <div class="form-group">
      <label>Текст под заголовком</label>
      <b-form-input v-model="form.text"/>
    </div>
    <div class="form-group">
      <label>Ссылка</label>
      <b-form-input v-model="form.link"/>
    </div>
    <div class="form-group">
      <b-form-checkbox v-model="form.shown" name="check-button" switch>
        Включено
      </b-form-checkbox>
    </div>
    <div class="form__btn">
      <b-button type="submit" variant="primary" v-if="getModalData.id">Сохранить</b-button>
      <b-button type="submit" variant="primary" v-else>Добавить</b-button>
    </div>
  </form>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'ModalSlide',
    computed: {
      ...mapGetters([
        'getModalData',
      ]),
    },
    data() {
      return {
        form: {
          title: '',
          text: '',
          link: '',
          shown: false,
        },
        filename: null,
      }
    },
    mounted() {
      if (this.getModalData.id) {
        this.form = {
          title: this.getModalData.title,
          text: this.getModalData.text,
          link: this.getModalData.link,
          shown: !!this.getModalData.shown,
        }
      }
    },
    methods: {
      ...mapActions([
        'storeSlide',
        'updateSlide',
      ]),
      onSubmit() {
        let formData = new FormData()
        if (this.$refs.image.files[0])
          formData.append('image', this.$refs.image.files[0])
        formData.append('title', this.form.title)
        formData.append('text', this.form.text)
        formData.append('link', this.form.link)
        formData.append('shown', +this.form.shown)

        if (this.getModalData.id)
          this.updateSlide([this.getModalData.id, formData])
        else
          this.storeSlide(formData)
      },
    }
  }
</script>

<style scoped>

</style>