<template>
  <div id="page" v-if="getPage">
    <h1>{{ getPage.title }}</h1>
    <div class="border-top pt-3">
      <b-container>
        <div v-html="getPage.body"></div>
      </b-container>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'Page',
    props: ['slug'],
    async beforeMount() {
      await this.queryPage(this.slug)
      document.title = this.getPage.meta_title + ' Смесители Krone в Москве. Аксессуары. Доставка по России.'
    },
    // async mounted() {
    //   await this.queryPage(this.slug)
    //   document.title = this.getPage.meta_title + ' Смесители Krone в Москве. Аксессуары. Доставка по России.'
    // },
    computed: {
      ...mapGetters([
        'getPage',
      ])
    },
    watch: {
      async 'slug'(val) {
        await this.queryPage(val)
        document.title = this.getPage.meta_title + ' Смесители Krone в Москве. Аксессуары. Доставка по России.'
      },
    },
    methods: {
      ...mapActions([
        'queryPage',
      ]),
    }
  }
</script>

<style>
  #page .container { text-align: initial }
</style>