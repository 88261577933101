import axios from 'axios'

export default {
  state: {
    list: [],
    category_id: null,
    product_form: null
  },
  mutations: {
    setProducts(state, payload) {
      state.list = payload
    },
    setProductForm(state, payload) {
      state.product_form = payload
    },
    setCategoryId(state, payload) {
      state.category_id = payload
    },
  },
  actions: {
    queryProducts({dispatch, commit, state}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'products', {params: {category_id: state.category_id}})
        .then(res => {
          dispatch('setPreloader', false)
          commit('setProducts', res.data)
        })
    },
    storeProduct({commit, dispatch}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'products', payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('queryProducts')
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    updateProduct({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'products/' + payload[0], payload[1])
        .then(res => {
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('queryProducts')
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
    destroyProduct({dispatch, commit}, product_id) {
      dispatch('setPreloader', true)
      axios.delete(process.env.VUE_APP_API_ENDPOINT + 'products/' + product_id)
        .then(res => {
          dispatch('queryProducts')
          dispatch('setToast', {status: 'success', message: res.data})
          dispatch('setPreloader', false)
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
    storeProductPhoto({commit, dispatch}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'products/' + payload[0] + '/photo', payload[1], {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', {status: 'success', message: res.data.message})
          commit('setProductForm', res.data.product)
          dispatch('setPreloader', false)
          dispatch('queryProducts')
          this.$forceUpdate()
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    destroyProductPhoto({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.delete(process.env.VUE_APP_API_ENDPOINT + 'products/' + payload[0] + '/' + payload[1])
        .then(res => {
          dispatch('queryProducts')
          dispatch('setToast', {status: 'success', message: res.data.message})
          commit('setProductForm', res.data.product)
          dispatch('setPreloader', false)
          this.$forceUpdate()
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
    questionProduct({dispatch, commit, state}, payload) {
      dispatch('setPreloader', true)
      payload.cart = state.list
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'question', payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('closeModal')
          dispatch('setToast', {status: 'success', message: res.data})
        })
    },
  },
  getters: {
    getProducts: state => {
      return state.list
    },
    getProductForm: state => {
      return state.product_form
    },
    getCategoryId: state => {
      return state.category_id
    },
  }
}