<template>
  <form class="form" @submit.prevent="onSubmit">
    <b-row>
      <b-col cols="6">
        <div class="form-group">
          <label>Имя</label>
          <input type="text" class="form-control" v-model="form.name"/>
        </div>
        <div class="form-group">
          <label>Email</label>
          <input type="email" class="form-control" v-model="form.email"/>
        </div>
        <div class="form-group">
          <label>Пароль</label>
          <input type="text" class="form-control" v-model="form.password"/>
        </div>
        <div class="form-group">
          <label>Скидка в %</label>
          <input type="text" class="form-control" v-model="form.discount"/>
        </div>
        <div class="form-group">
          <label>Роль</label>
          <b-form-select v-model="form.role" :options="getRoles"></b-form-select>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="form-group">
          <label>Компания</label>
          <input type="text" class="form-control" v-model="form.company"/>
        </div>
        <div class="form-group">
          <label>ИНН</label>
          <input type="text" class="form-control" v-model="form.inn"/>
        </div>
        <div class="form-group">
          <label>Юридический адрес</label>
          <input type="text" class="form-control" v-model="form.address"/>
        </div>
        <div class="form-group">
          <label>Телефон</label>
          <input type="text" class="form-control" v-model="form.phone"/>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="form-group">
          <label>Заметки</label>
          <textarea class="form-control" v-model="form.notes"></textarea>
        </div>
      </b-col>
    </b-row>
    <div class="form__btn">
      <b-button type="submit" variant="primary" v-if="getModalData.id">Сохранить</b-button>
      <b-button type="submit" variant="primary" v-else>Добавить</b-button>
    </div>
  </form>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'ModalUser',
    computed: {
      ...mapGetters([
        'getModalData',
        'getRoles',
      ]),
    },
    data() {
      return {
        form: {
          name: '',
          email: '',
          discount: 0,
          role: 0,
          company: '',
          inn: '',
          address: '',
          phone: '',
          password: '',
          notes: '',
        },
      }
    },
    mounted() {
      if (this.getModalData.id) {
        this.form = {
          name: this.getModalData.name,
          email: this.getModalData.email,
          discount: this.getModalData.discount,
          role: this.getModalData.role,
          company: this.getModalData.company,
          inn: this.getModalData.inn,
          address: this.getModalData.address,
          phone: this.getModalData.phone,
          notes: this.getModalData.notes,
        }
      }
    },
    methods: {
      ...mapActions([
        'storeUser',
        'updateUser',
        'setToast',
      ]),
      onSubmit() {
        if (this.getModalData.id)
          this.updateUser([this.getModalData.id, this.form])
        else
          this.storeUser(this.form)
      },
    }
  }
</script>

<style scoped>

</style>