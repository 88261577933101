<template>
  <form class="form" @submit.prevent="onSubmit" v-if="getProductForm">
    <div class="form-group" v-if="getProductForm.photos">
      <div v-for="photo of getProductForm.photos" class="mb-2 pb-2 border-bottom">
        <img :src="photo.photo" style="height: 100px">
        <b-button variant="link" @click="onDeletePhoto(photo)">удалить</b-button>
      </div>
    </div>
    <div class="form-group">
      <label>Фото</label>
      <b-form-file accept=".jpg, .png, .gif" v-model="photo"/>
    </div>
    <div class="form__btn">
      <b-button type="submit" variant="primary">Загрузить</b-button>
    </div>
  </form>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'ModalProductPhotos',
    computed: {
      ...mapGetters([
        'getProductForm',
      ]),
    },
    data() {
      return {
        photo: null,
      }
    },
    methods: {
      ...mapActions([
        'storeProductPhoto',
        'destroyProductPhoto',
        'setToast',
      ]),
      onSubmit() {
        if (this.photo) {
          let formData = new FormData();
          formData.append('photo', this.photo);
          this.storeProductPhoto([this.getProductForm.id, formData])
        }
      },
      onDeletePhoto(photo) {
        this.destroyProductPhoto([this.getProductForm.id, photo.id])
      }
    }
  }
</script>

<style scoped>

</style>