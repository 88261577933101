import axios from 'axios'

export default {
  state: {
    list: [],
  },
  mutations: {
    setCollections(state, payload) {
      state.list = payload
    },
  },
  actions: {
    queryCollections({dispatch, commit}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'collections')
        .then(res => {
          dispatch('setPreloader', false)
          commit('setCollections', res.data)
        })
    },
    storeCollection({commit, dispatch}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'collections', payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('queryCollections')
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    updateCollection({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'collections/' + payload[0], payload[1])
        .then(res => {
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('queryCollections')
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
    destroyCollection({dispatch, commit}, collection_id) {
      dispatch('setPreloader', true)
      axios.delete(process.env.VUE_APP_API_ENDPOINT + 'collections/' + collection_id)
        .then(res => {
          dispatch('queryCollections')
          dispatch('setToast', {status: 'success', message: res.data})
          dispatch('setPreloader', false)
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
  },
  getters: {
    getCollections: state => {
      return state.list
    },
    getCollectionsOptions: state => {
      let options = []
      options.push({value: 0, text: 'Нет'})
      if (state.list)
        for (let item of state.list)
          options.push({value: item.id, text: item.name})
      return options
    },
  }
}