import axios from 'axios'

export default {
  state: {
    list: [],
    category_properties: [],
    types: [
      {value: 'num', text: 'Числовое поле'},
      {value: 'list', text: 'Выбор из списка'}
    ]
  },
  mutations: {
    setProperties(state, payload) {
      state.list = payload
    },
    setCategoryProperties(state, payload) {
      state.category_properties = payload
    },
  },
  actions: {
    queryProperties({dispatch, commit}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'properties')
        .then(res => {
          dispatch('setPreloader', false)
          commit('setProperties', res.data)
        })
    },
    queryCategoryProperties({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'category_properties/' + payload)
        .then(res => {
          dispatch('setPreloader', false)
          commit('setCategoryProperties', res.data)
        })
    },
    storeProperty({commit, dispatch}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'properties', payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('queryProperties')
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    updateProperty({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'properties/' + payload[0], payload[1])
        .then(res => {
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('queryProperties')
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
    destroyProperty({dispatch, commit}, property_id) {
      dispatch('setPreloader', true)
      axios.delete(process.env.VUE_APP_API_ENDPOINT + 'properties/' + property_id)
        .then(res => {
          dispatch('queryProperties')
          dispatch('setToast', {status: 'success', message: res.data})
          dispatch('setPreloader', false)
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
    propertyCategory({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'property_category', payload)
        .then(res => {
          dispatch('queryProperties')
          dispatch('queryCategoryProperties', payload.category_id)
          dispatch('setToast', {status: 'success', message: res.data})
          dispatch('setPreloader', false)
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
    savePropertyValues({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'property_values', payload)
        .then(res => {
          dispatch('setToast', {status: 'success', message: res.data})
          dispatch('setPreloader', false)
          dispatch('queryProducts', payload.category_id)
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
  },
  getters: {
    getProperties: state => {
      return state.list
    },
    getCategoryProperties: state => {
      return state.category_properties
    },
    getPropertyTypes: state => {
      return state.types
    },
    getPropertiesOptions: state => {
      let isset_options = []
      if (state.category_properties)
        for (let item of state.category_properties)
          isset_options.push(item.property.id)

      let options = []
      if (state.list)
        for (let item of state.list)
          if (!isset_options.includes(item.id))
            options.push({value: item.id, text: item.label})
      return options
    },
    getCategoryPropertiesOptions: state => {
      let options = []
      if (state.category_properties)
        for (let item of state.category_properties)
          options.push({value: item.property.id, text: item.property.label})
      return options
    },
  }
}