<template>
  <div id="pages">
    <h1>Страницы</h1>

    <ul class="list-group text-left">
      <template v-for="page of getPages">
        <li class="list-group-item">
          <a :href="'page/' + page.slug">{{ page.title }}</a>
          <b-icon icon="pencil-square" class="mx-2 cursor_pointer" variant="success" @click="onShowEditModal(page)"/>
          <b-icon icon="trash" class="cursor_pointer" variant="danger" @click="onDelete(page)"/>
        </li>
      </template>
      <li class="list-group-item">
        <b-button variant="link" class="cursor_pointer p-0" @click="onShowCreateModal">Добавить страницу</b-button>
      </li>
    </ul>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'Pages',
    computed: {
      ...mapGetters([
        'getPages',
      ])
    },
    mounted() {
      this.queryPages()
    },
    methods: {
      ...mapActions([
        'queryPages',
        'destroyPage',
        'showModal',
      ]),
      onShowCreateModal() {
        this.showModal({modal: 'ModalPage', title: 'Добавление страницы', size: 'lg', data: {}})
      },
      onShowEditModal(page) {
        this.showModal({modal: 'ModalPage', title: 'Редактирование страницы', size: 'lg', data: page})
      },
      onDelete(page) {
        this.$bvModal.msgBoxConfirm('Удалить страницу "' + page.title + '"?')
          .then(value => {
            this.destroyPage(page.id)
          })
      }
    }
  }
</script>

<style scoped>

</style>