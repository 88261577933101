<template>
  <transition name="fade">
    <b-modal id="modal"
             :size="getModalSize"
             hide-footer
             :visible="getModalStatus"
             :title="getModalTitle"
             :no-close-on-backdrop="getModal === 'Cart'">
      <ModalLogin v-if="getModal === 'ModalLogin'"/>
      <ModalProduct v-if="getModal === 'ModalProduct'"/>
      <ModalProductPhotos v-if="getModal === 'ModalProductPhotos'"/>
      <ModalCategory v-if="getModal === 'ModalCategory'"/>
      <ModalCollection v-if="getModal === 'ModalCollection'"/>
      <ModalUser v-if="getModal === 'ModalUser'"/>
      <ModalProperty v-if="getModal === 'ModalProperty'"/>
      <ModalPropertyValues v-if="getModal === 'ModalPropertyValues'"/>
      <ModalSetting v-if="getModal === 'ModalSetting'"/>
      <Cart v-if="getModal === 'Cart'"/>
      <ModalProductInfo v-if="getModal === 'ModalProductInfo'"/>
      <ModalProductSupport v-if="getModal === 'ModalProductSupport'"/>
      <ModalPage v-if="getModal === 'ModalPage'"/>
      <ModalSlide v-if="getModal === 'ModalSlide'"/>
    </b-modal>
  </transition>
</template>

<script>
  import ModalLogin from './ModalLogin'
  import ModalProduct from './ModalProduct'
  import ModalProductPhotos from './ModalProductPhotos'
  import ModalCategory from './ModalCategory'
  import ModalCollection from './ModalCollection'
  import ModalUser from './ModalUser'
  import ModalProperty from './ModalProperty'
  import ModalPropertyValues from './ModalPropertyValues'
  import ModalSetting from './ModalSetting'
  import Cart from './Cart'
  import ModalProductInfo from './ModalProductInfo'
  import ModalProductSupport from './ModalProductSupport'
  import ModalPage from './ModalPage'
  import ModalSlide from './ModalSlide'

  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'Modal',
    components: {
      ModalLogin,
      ModalProduct,
      ModalProductPhotos,
      ModalCategory,
      ModalCollection,
      ModalUser,
      ModalProperty,
      ModalPropertyValues,
      ModalSetting,
      Cart,
      ModalProductInfo,
      ModalProductSupport,
      ModalPage,
      ModalSlide,
    },
    mounted() {
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        this.closeModal()
      })
    },
    computed: {
      ...mapGetters([
        'getModalStatus',
        'getModalTitle',
        'getModalSize',
        'getModal',
      ])
    },
    methods: {
      ...mapActions(['closeModal'])
    }
  }
</script>

<style lang="scss">
  .modal {
    display: block !important;
    background: #0009 !important;
    overflow: auto !important;

    .modal-content {
      border-radius: 0;
    }

    .modal-header {
      background-color: #f6f6f6;
      color: #242729;
      padding: 20px 30px;
      font-size: 14px;
    }
  }
</style>