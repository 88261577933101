import axios from 'axios'

export default {
  state: {
    list: [],
  },
  mutations: {
    setSlides(state, payload) {
      state.list = payload
    },
  },
  actions: {
    querySlides({dispatch, commit}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'slides')
        .then(res => {
          dispatch('setPreloader', false)
          commit('setSlides', res.data)
        })
    },
    storeSlide({commit, dispatch}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'slides', payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('querySlides')
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    updateSlide({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'slides/' + payload[0], payload[1])
        .then(res => {
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('querySlides')
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
    destroySlide({dispatch, commit}, slide_id) {
      dispatch('setPreloader', true)
      axios.delete(process.env.VUE_APP_API_ENDPOINT + 'slides/' + slide_id)
        .then(res => {
          dispatch('querySlides')
          dispatch('setToast', {status: 'success', message: res.data})
          dispatch('setPreloader', false)
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
  },
  getters: {
    getSlides: state => {
      return state.list
    },
  }
}