<template>
  <header id="header" class="text-light">
    <div class="border-bottom py-3">
      <b-container>
        <b-row class="px-2">
          <b-col cols="12" md="4" class="text-center text-md-left">
            <b-navbar-brand to="/" class="m-0">
              <img src="/logo.png" alt="Krone">
            </b-navbar-brand>
          </b-col>
          <b-col cols="12" md="4" class="text-center d-flex justify-content-center align-items-center">
            <div class="phone">{{ getPhone }}</div>
          </b-col>
          <b-col cols="12" md="4" class="text-center text-md-right">
            <div style="font-size: 2rem;">
              <div class="position-relative d-inline-block">
                <b-icon icon="cart3"
                        class="border border-warning rounded p-2 cursor_pointer"
                        variant="warning"
                        style="border-radius: 15px !important;"
                        @click="onShowCart()"/>
                <span class="cart_count" @click="onShowCart()">{{ getCart.length }}</span>
              </div>
              <span class="cursor_pointer" @click="profile">
                <b-icon icon="person"
                        class="border border-info rounded p-2 ml-2"
                        variant="info"
                        style="border-radius: 15px !important;"/>
              </span>
              <router-link to="/dashboard" v-if="getAuthUser.id && getAuthUser.role === 'admin'">
                <b-icon icon="gear"
                        class="border border-success rounded p-2 ml-2"
                        variant="success"
                        style="border-radius: 15px !important;"/>
              </router-link>
              <span class="cursor_pointer" v-if="getAuthUser.id" @click="logout">
                <b-icon icon="box-arrow-right" class="border border-danger rounded p-2 ml-2" variant="danger"
                        style="border-radius: 15px !important;"/>
              </span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container>
      <b-navbar toggleable="lg" type="dark" class="px-0">
        <b-navbar-toggle target="nav-catalog"/>

        <b-collapse id="nav-catalog" is-nav>
          <b-navbar-nav>
            <template v-for="category of getCategories">
              <b-nav-item :to="'/catalog?category=' + category.slug">{{ category.name }}</b-nav-item>
            </template>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item :to="'/reviews'">Отзывы</b-nav-item>
            <b-nav-item v-for="page of getPages" :key="page.id" :to="'/page/' + page.slug">{{ page.title }}</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
  </header>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'Header',
    computed: {
      ...mapGetters([
        'getCart',
        'getAuthUser',
        'getPhone',
        'getCategories',
        'getPages',
      ])
    },
    mounted() {
      this.querySettings()
      this.queryCategories()
      this.queryPages()
    },
    methods: {
      ...mapActions([
        'querySettings',
        'queryCategories',
        'queryPages',
        'showModal',
        'logout',
      ]),
      profile() {
        if (this.getAuthUser.id) {
          this.$router.push('/profile')
        } else {
          this.showModal({modal: 'ModalLogin', title: 'Аккаунт'})
        }
      },
      onShowCart() {
        this.showModal({modal: 'Cart', title: 'Корзина', size: 'lg'})
      }
    }
  }
</script>

<style lang="scss">
  #header {
    background: #343a40;

    .cart_count {
      position: absolute;
      top: 4px;
      right: -5px;
      font-size: 13px;
      background: #00000080;
      border-radius: 10px;
      display: block;
      padding: 0 6px;
      cursor: pointer;
    }

    .phone {
      font-family: 'Open Sans';
      letter-spacing: 0.2em;
    }

    .navbar {
      .nav-item {
        margin: 0 15px;

        @media (min-width: 991.98px) {
          &:first-child {
            margin-left: 0
          }
          &:last-child {
            margin-right: 0
          }
        }
      }

      a {
        font-size: 14px;
        text-transform: uppercase;
        color: #fffa !important;
        font-weight: 900;

        &:hover {
          color: #fff !important;
        }
      }

      .dropdown-menu {
        a {
          color: #333 !important;
          font-size: 12px;
        }
      }
    }
  }
</style>