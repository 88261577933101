<template>
  <div id="app">
    <loading :active.sync="preloaderStatus" color="#42b983" :is-full-page="true"></loading>
    <Header/>

    <div class="my-4">
      <router-view/>
    </div>

    <Footer/>
    <Modal/>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/vue-loading.css'

  import Header from './components/layouts/Header'
  import Footer from './components/layouts/Footer'
  import Modal from './components/modals/Modal'

  export default {
    components: {
      Header,
      Footer,
      Modal,
      Loading,
    },
    computed: {
      ...mapGetters([
        'preloaderStatus',
      ]),
    }
  }
</script>

<style lang="scss">
  $success: #47b370;
  $danger: #e62517;
  $primary: #0094d9;
  $secondary: #808080;
  $border-color: #ccc;
  $border-radius: 0;
  $btn-border-radius: 0;
  $btn-border-radius-lg: 0;
  $btn-border-radius-sm: 0;
  $input-border-radius: 0;
  $input-border-radius-lg: 0;
  $input-border-radius-sm: 0;


  @import '../node_modules/bootstrap/scss/bootstrap';
  @import '../node_modules/bootstrap-vue/src/index.scss';

  *:focus { outline: 0 !important }

  #app {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #e6e6e6;

    h1 {
      color: #333;
      font-family: 'Open Sans', Arial, sans-serif;
      font-style: normal;
      font-weight: 300;
      text-transform: uppercase;
    }
  }

  .bg-grey { background: #f5f5f5 }
  .bg-black { background: #1a1a1a !important }

  .cursor_pointer { cursor: pointer }

  .white_space { white-space: pre; }
  .text_small { font-size: 13px; }

  .btn:focus,
  .btn.focus { box-shadow: none !important; }

  .opacity9 {opacity: .9}
</style>
