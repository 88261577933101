import router from '../router'
import axios from 'axios'

export default {
  state: {
    user: {},
    token: null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setToken(state, token) {
      state.token = token
      axios.defaults.headers.common = {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    },
    logout(state) {
      state.user = {}
      state.token = null
    },
  },
  actions: {
    queryProfile({dispatch, commit}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'profile')
        .then(res => {
          dispatch('setPreloader', false)
          commit('setUser', res.data.user)
        })
    },
    register({commit, dispatch}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'register', payload)
        .then(res => {
          dispatch('setPreloader', false)
          if (res.status === 200) {
            commit('setToken', res.data.token)
            commit('setUser', res.data.user)
            dispatch('setToast', {status: 'success', message: res.data.message})
            router.go()
          } else {
            dispatch('setToast', {status: 'warning', message: 'Registration failed'})
          }
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    login({commit, dispatch}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'login', payload)
        .then(res => {
          dispatch('setPreloader', false)
          commit('setUser', res.data.user)
          commit('setToken', res.data.token)
          dispatch('setToast', {status: 'success', message: 'Authentication success'})
          router.go()
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch('setToast', {status: 'warning', message: 'Email or password is not correct'})
          } else if (error.response.status === 423) {
            dispatch('setToast', {status: 'warning', message: 'Account not activated'})
          } else {
            dispatch('setToastHttpError', error.response)
          }
          dispatch('setPreloader', false)
        })
    },
    logout({commit, dispatch}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'logout')
        .then(res => {
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('setPreloader', false)
        })
      commit('logout')
      window.location = '/';
    },
    restorePassword({commit, dispatch}, payload) {
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'restore-password', payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal')
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    }
  },
  getters: {
    getAuthUser: state => {
      return state.user
    },
  }
}