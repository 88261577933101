<template>
  <div id="dashboard">
    <b-container>
      <b-row class="py-3">
        <b-col cols="3">
          <b-list-group>
            <b-list-group-item class="cursor_pointer" :class="{active: tab === 'orders'}" @click="tab = 'orders'">
              Заказы
            </b-list-group-item>
            <b-list-group-item class="cursor_pointer" :class="{active: tab === 'products'}" @click="tab = 'products'">
              Товары
            </b-list-group-item>
            <b-list-group-item class="cursor_pointer" :class="{active: tab === 'categories'}" @click="tab = 'categories'">
              Категории
            </b-list-group-item>
            <b-list-group-item class="cursor_pointer" :class="{active: tab === 'collections'}" @click="tab = 'collections'">
              Коллекции
            </b-list-group-item>
            <b-list-group-item class="cursor_pointer" :class="{active: tab === 'users'}" @click="tab = 'users'">
              Пользователи
            </b-list-group-item>
            <b-list-group-item class="cursor_pointer" :class="{active: tab === 'properties'}" @click="tab = 'properties'">
              Свойства
            </b-list-group-item>
            <b-list-group-item class="cursor_pointer" :class="{active: tab === 'slides'}" @click="tab = 'slides'">
              Слайдер
            </b-list-group-item>
            <b-list-group-item class="cursor_pointer" :class="{active: tab === 'pages'}" @click="tab = 'pages'">
              Страницы
            </b-list-group-item>
            <b-list-group-item class="cursor_pointer" :class="{active: tab === 'settings'}" @click="tab = 'settings'">
              Настройки
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col cols="9">
          <Orders v-if="tab === 'orders'"/>
          <Products v-if="tab === 'products'"/>
          <Categories v-if="tab === 'categories'"/>
          <Collections v-if="tab === 'collections'"/>
          <Users v-if="tab === 'users'"/>
          <Properties v-if="tab === 'properties'"/>
          <Slides v-if="tab === 'slides'"/>
          <Pages v-if="tab === 'pages'"/>
          <Settings v-if="tab === 'settings'"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'

  import Orders from "./Orders";
  import Products from "./Products";
  import Categories from "./Categories";
  import Collections from "./Collections";
  import Users from "./Users";
  import Properties from "./Properties";
  import Pages from "./Pages";
  import Slides from "./Slides";
  import Settings from "./Settings";
  export default {
    name: 'Dashboard',
    components: {Orders, Products, Categories, Collections, Users, Properties, Pages, Slides, Settings},
    data() {
      return {
        tab: 'orders',
      }
    },
    mounted() {
      this.querySettings()
      this.queryCategories()
    },
    methods: {
      ...mapActions([
        'querySettings',
        'queryCategories',
      ]),
    }
  }
</script>

<style>

</style>