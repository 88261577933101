<template>
  <div id="product">
    <h1>{{ getProduct.title }}</h1>
    <div class="bg-light mt-4">
      <b-container>
        <b-row class="pt-3">
          <b-col cols="12" md="6" lg="3" order="1" order-lg="0">
            <b-row v-if="getProduct.photos && getProduct.photos.length > 1">
              <b-col cols="6" v-for="(photo, index) of getProduct.photos" :key="index" class="mb-3">
                <b-img thumbnail fluid :src="photo.photo" class="cursor_pointer" @click="active_photo = index"/>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" lg="6" order="0" order-lg="1">
            <div v-if="getProduct.photos">
              <div v-for="(photo, index) of getProduct.photos" :key="index">
                <b-img v-show="index == active_photo" :src="photo.photo" fluid-grow class="mb-3"/>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="6" lg="3" order="2" order-lg="2">
            <div class="bg-black text-light text-left p-3">
              <div class="text-gray">
                <small class="font-weight-bold">
                  <span class="text-secondary">КОД ТОВАРА:</span> <span
                    class="text-primary">{{ getProduct.article }}</span>
                </small>
              </div>
              <div class="mt-3">Цена:</div>
              <div class="text-center">
                <span class="h1">{{ getProduct.price }}</span>
                <small class="font-weight-bold">РУБ.</small>
              </div>
              <div class="mt-3">
                <b-button size="lg" variant="outline-secondary" class="w-100" @click="addToCart(getProduct)">В КОРЗИНУ
                </b-button>
              </div>
              <div class="mt-3 pb-3 border-bottom text-center">
                Наличие: <span class="text-success">{{ stock }}</span>
              </div>
              <div class="mt-3 text-center">
                <b-button variant="link" @click="onShowModalSupport">
                  <small class="text-light">Задать вопрос по товару
                    <b-icon icon="question-circle"/>
                  </small>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container class="mt-3">
      <b-row>
        <b-col cols="12" md="10" offset-md="1" lg="6" offset-lg="3">
          <div class="bg-light p-3">
            <h3 class="mt-0 mb-2 text-center">Характеристики</h3>
            <table class="table b-table">
              <tbody>
              <tr role="row" v-for="(value, prop) in props">
                <td><div class="font-weight-bold text-right">{{ prop }}</div></td>
                <td><div class="text-left" v-html="value"></div></td>
              </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex'

  export default {
    name: 'Product',
    props: ['slug'],
    data() {
      return {
        active_photo: 0
      }
    },
    computed: {
      ...mapGetters([
        'getProduct',
      ]),
      stock() {
        if (this.getProduct.stock >= 20) {
          return 'Много'
        }
        if (this.getProduct.stock >= 1) {
          return 'Мало'
        }
        return 'Под заказ'
      },
      props() {
        let data = this.getProduct.property_values
        let obj = {}
        for (let item in data) {
          obj[data[item].property.property.label] = data[item].value
        }
        if (this.getProduct.description)
          obj['Описание'] = this.getProduct.description
        return obj
      },
    },
    mounted() {
      this.queryProduct(this.slug)
    },
    destroyed() {
      this.clearProduct()
    },
    methods: {
      ...mapActions([
        'queryProduct',
        'addToCart',
        'showModal',
      ]),
      ...mapMutations([
        'clearProduct'
      ]),
      onShowModalInfo(type) {
        this.showModal({modal: 'ModalProductInfo', size: 'lg', data: {tab: type}})
      },
      onShowModalSupport() {
        this.showModal({modal: 'ModalProductSupport', title: 'Вопрос по товару', data: this.getProduct})
      }
    }
  }
</script>

<style lang="scss">

</style>