<template>
  <div id="home">
    <h1>Смесители KRONE</h1>
    <div class="border-top">
      <b-container class="mt-3">
        <div class="mb-4" v-if="getSlides.length">
          <b-carousel
              id="carousel-1"
              :interval="4000"
              controls
              indicators
              background="#ababab"
              img-width="1024"
              img-height="300"
              style="text-shadow: 1px 1px 2px #333;">
            <div class="cursor_pointer" v-for="slide of getSlides" @click="onClickSlide(slide)">
              <b-carousel-slide :img-src="slide.image"
                                :caption="slide.title"
                                :text="slide.text">
              </b-carousel-slide>
            </div>
          </b-carousel>
        </div>

        <h3 class="mb-3">Каталог</h3>
        <b-row align-h="center">
          <b-col cols="12" md="6" lg="4" class="mb-3" v-for="category of getCategories" :key="category.id">
            <b-card bg-variant="dark" text-variant="white" class="card_product">
              <b-card-text class="m-0">
                <div class="collection_photo" :style="'background-image: url(' + category.image + ')'"></div>
              </b-card-text>
              <b-card-footer class="pt-0">
                <div class="p-2">
                  <span>{{ category.name }}</span>
                </div>
                <b-button :to="'/catalog?category=' + category.slug" squared variant="outline-danger">Перейти</b-button>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>

        <h3 class="mb-3">Коллекции</h3>
        <b-row align-h="center">
          <b-col cols="12" md="6" lg="4" class="mb-3" v-for="сollection of getCollections" :key="сollection.id">
            <b-card class="card_product" bg-variant="dark" text-variant="white">
              <b-card-text class="m-0">
                <div class="collection_photo" :style="'background-image: url(' + сollection.photo + ')'"></div>
              </b-card-text>
              <b-card-footer class="pt-0">
                <div class="p-2">
                  <span>{{ сollection.name }}</span>
                </div>
                <b-button :to="'/сollections/' + сollection.slug" squared variant="outline-danger">Перейти</b-button>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>

        <h3 class="mb-3">5 причин купить смеситель KRONE</h3>
        <b-card-group deck>
          <b-card header="Современный дизайн" header-text-variant="danger" class="advantage mb-4 text-center">
            <b-card-text>
              Наши дизайнеры следят за новыми тенденциями на Европейском рынке и постоянно разрабатывают новые
              модели.
            </b-card-text>
          </b-card>
          <b-card header="Новые технологии" header-text-variant="danger" class="advantage mb-4 text-center">
            Только экологически чистые и безопасные материалы в соответствии с Европейскими и Российскими
            стандартами.
          </b-card>
          <b-card header="Легкая установка" header-text-variant="danger" class="advantage mb-4 text-center">
            Установка доступна абсолютно любому человеку благодаря упрощенному креплению смесителя.
          </b-card>
          <b-card header="Немецкое качество" header-text-variant="danger" class="advantage mb-4 text-center">
            Постоянный контроль специалистов KRONE качества сплавов и сборки на заводе.
          </b-card>
          <b-card header="Бесплатный сервис" header-text-variant="danger" class="advantage mb-4 text-center">
            Выезд специалиста бесплатно в течении всего срока гарантии.
          </b-card>
        </b-card-group>
      </b-container>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'Home',
    computed: {
      ...mapGetters([
        'getCategories',
        'getSlides',
        'getCollections',
        'getSettings',
      ])
    },
    mounted() {
      this.querySlides()
      this.queryCollections()
      document.title = 'Смесители Krone в Москве. Аксессуары. Доставка по России.'
    },
    methods: {
      ...mapActions([
        'querySlides',
        'queryCollections',
      ]),
      onClickSlide(slide) {
        if (slide.link)
          this.$router.push(slide.link)
      }
    }
  }
</script>

<style lang="scss">
  .advantage {
    min-width: 300px;
  }

  .collection_photo {
    height: 330px;
    @media (min-width: 576px) {
      height: 510px;
    }
    @media (min-width: 768px) {
      height: 330px;
    }
    @media (min-width: 991.98px) {
      height: 290px;
    }
    @media (min-width: 1200px) {
      height: 350px;
    }
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
</style>