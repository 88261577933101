<template>
  <div id="properties">
    <h1>Свойства товаров</h1>

    <table class="table bg-light">
      <thead>
        <tr>
          <th>Имя</th>
          <th>Тип</th>
          <th>Варианты</th>
          <th>Мера</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      <tr v-for="property of getProperties">
        <td>{{ property.label }}</td>
        <td>{{ property.type }}</td>
        <td class="white_space">{{ property.variants }}</td>
        <td>{{ property.unit }}</td>
        <td>
          <b-icon icon="pencil-square" class="mx-2 cursor_pointer" variant="success" @click="onShowEditModal(property)"/>
          <b-icon icon="trash" class="cursor_pointer" variant="danger" @click="onDelete(property)"/>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="6">
          <b-button variant="link" class="cursor_pointer p-0" @click="onShowCreateModal">Добавить свойство</b-button>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'Properties',
    computed: {
      ...mapGetters([
        'getProperties',
      ])
    },
    mounted() {
      this.queryProperties()
    },
    methods: {
      ...mapActions([
        'queryProperties',
        'destroyProperty',
        'showModal',
      ]),
      onShowCreateModal() {
        this.showModal({modal: 'ModalProperty', title: 'Добавление свойства', data: {}})
      },
      onShowEditModal(property) {
        this.showModal({modal: 'ModalProperty', title: 'Редактирование свойства', data: property})
      },
      onDelete(property) {
        this.$bvModal.msgBoxConfirm('Удалить свойство "' + property.label + '"?')
          .then(value => {
            this.destroyProperty(property.id)
          })
      }
    }
  }
</script>

<style>

</style>