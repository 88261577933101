<template>
  <div id="orders">
    <h1>Список заказов</h1>
    <table class="table table-striped bg-light">
      <thead>
      <tr>
        <th scope="col">№</th>
        <th scope="col" style="width: 170px;">Статус</th>
        <th scope="col" class="text-center">Дата</th>
        <th scope="col" class="text-center">Заказ</th>
        <th scope="col" class="text-center">Клиент</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item of getOrders">
        <td>{{ item.id }}</td>
        <td>
          <b-form-select v-model="item.status" :options="statuses" @change="changeStatus(item.id, $event)" size="sm" class="mt-3"></b-form-select>
          <template v-if="item.status === 'Ожидает оплаты'">
            <a :href="item.yookassa_url" target="_blank">Cсылка для оплаты</a>
          </template>
        </td>
        <td>{{ item.created_at }}</td>
        <td class="text_small">
          <div v-for="product of item.products" class="border-bottom">
            <div>{{ product.title }} <span class="text-muted">({{ product.article }})</span></div>
            <div>{{ product.price }} руб. - {{ product.count }} шт.</div>
          </div>
          <div class="font-weight-bold">Итого: {{ item.summ }} руб.</div>
        </td>
        <td class="text_small">
          <div>{{ item.name }}</div>
          <div>{{ item.address }}</div>
          <div>{{ item.phone }}</div>
          <div>{{ item.email }}</div>
        </td>
        <td></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'Orders',
    data() {
      return {
        statuses: [
            'Новый',
            'Ожидает оплаты',
            'Оплачен',
            'Завершен',
            'Отменен',
        ]
      }
    },
    mounted() {
      this.queryOrders()
    },
    computed: {
      ...mapGetters([
        'getAuthUser',
        'getOrders'
      ])
    },
    methods: {
      ...mapActions([
        'queryOrders',
        'updateOrder',
      ]),
      changeStatus(order_id, event) {
        let data = {'status': event}
        this.updateOrder([order_id, data])
      }
    }
  }
</script>

<style scoped>

</style>