<template>
  <div id="home">
    <h1>Отзывы</h1>
    <div class="border-top pt-2">
      <b-container>
        <b-card :title="review.name" :sub-title="review.created_at" v-for="review of getReviews" :key="review.id" class="mb-2">
          <div class="text-center">
            <star-rating
                :rating="review.rating"
                :show-rating="false"
                :active-color="['#ae0000','#003333']"
                :active-border-color="['#F6546A','#a8c3c0']"
                :border-width="4"
                :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                :star-size="25"
                :active-on-click="true"
                :read-only="true"
                :inline="true"/>
          </div>
          <b-card-text>{{ review.text }}</b-card-text>
          <b-link v-if="getAuthUser.role == 'admin'" href="#" class="card-link" @click="onDelete(review)">Удалить</b-link>
        </b-card>

        <h3>Оставить отзыв</h3>
        <b-form @submit.prevent="onSubmit" class="pt-3">
          <star-rating
              v-model="form.rating"
              :rating="form.rating"
              :show-rating="false"
              :animate="true"
              :active-color="['#ae0000','#003333']"
              :active-border-color="['#F6546A','#a8c3c0']"
              :border-width="4"
              :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
              :star-size="35"
              :active-on-click="true"
              :clearable="true"/>

          <b-form-input id="form_name" v-model="form.name" placeholder="Введите Ваше имя" class="my-2"/>

          <b-form-textarea
              id="textarea"
              v-model="form.text"
              placeholder="Введите отзыв"
              rows="3"
              max-rows="6"/>

          <b-button type="submit" variant="primary" class="my-2">Отправить</b-button>
        </b-form>
      </b-container>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import StarRating from 'vue-star-rating'

  export default {
    name: 'Reviews',
    components: {
      StarRating
    },
    data() {
      return {
        form: {
          name: '',
          rating: 4,
          text: '',
        }
      }
    },
    computed: {
      ...mapGetters([
        'getReviews',
        'getAuthUser',
      ])
    },
    mounted() {
      this.queryReviews()
    },
    methods: {
      ...mapActions([
        'queryReviews',
        'storeReview',
        'destroyReview',
      ]),
      onSubmit() {
        this.storeReview(this.form)
        this.form = {
          name: '',
          rating: 4,
          text: '',
        }
      },
      onDelete(review) {
        if (confirm('Удалить отзыв от ' + review.name + '?')) {
          this.destroyReview(review.id)
        }
      }
    }
  }
</script>

<style lang="scss">

</style>