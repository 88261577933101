<template>
  <b-row>
    <ProductsGridItem v-for="product of getCatalog" :key="product.id" :product="product" v-if="!collection"/>
    <CollectionProductsGridItem v-for="product of getCatalog" :key="product.id" :product="product" v-if="collection"/>
  </b-row>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  import ProductsGridItem from './ProductsGridItem'
  import CollectionProductsGridItem from './CollectionProductsGridItem'

  export default {
    name: 'ProductsGrid',
    props: [
      'collection'
    ],
    components: {
      ProductsGridItem,
      CollectionProductsGridItem,
    },
    computed: {
      ...mapGetters([
        'getCatalog',
      ])
    },
    methods: {
      ...mapActions([
        'showModal',
      ]),
    }
  }
</script>

<style>

</style>