import axios from 'axios'

export default {
  state: {
    list: [],
    filters: {},
    filters_data: {},
    sort_options: [
      {text: 'По категории', value: 'category_id'},
      {text: 'Дешевые', value: 'price asc'},
      {text: 'Дорогие', value: 'price desc'},
    ],
    product: {},
    show_sidebar: false,
  },
  mutations: {
    setCatalog(state, payload) {
      state.list = payload
    },
    setFilters(state, payload) {
      state.filters = payload
    },
    setFiltersData(state, payload) {
      state.filters_data = payload
    },
    setSort(state, payload) {
      state.filters_data.sort = payload
    },
    setProduct(state, payload) {
      state.product = payload
    },
    toggleSidebarStatus(state) {
      state.show_sidebar = !state.show_sidebar
    },
    clearProduct(state) {
      state.product = {}
    },
  },
  actions: {
    queryFilters({dispatch, commit, state}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'filters', {params: state.filters_data})
        .then(res => {
          dispatch('setPreloader', false)
          commit('setFilters', res.data)
        })
    },
    queryCatalog({dispatch, commit, state}, payload) {
      dispatch('setPreloader', true)
      let params = state.filters_data
      if (payload) {
        params = payload
        commit('setFiltersData', payload)
      }
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'catalog', {params: params})
        .then(res => {
          dispatch('setPreloader', false)
          commit('setCatalog', res.data)
        })
    },
    queryProduct({dispatch, commit, state}, payload) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'products/' + payload)
        .then(res => {
          dispatch('setPreloader', false)
          commit('setProduct', res.data)
        })
    }
  },
  getters: {
    getSortOptions: state => {
      return state.sort_options
    },
    getSort: state => {
      return state.filters_data.sort
    },
    getCatalog: state => {
      return state.list
    },
    getFilters: state => {
      return state.filters
    },
    getFiltersData: state => {
      return state.filters_data
    },
    getProduct: state => {
      return state.product
    },
    getSidebarStatus: state => {
      return state.show_sidebar
    },
  }
}