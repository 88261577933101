<template>
  <form class="form" @submit.prevent="onSubmit">
    <div class="row">
      <div class="col-6" v-for="pv of getCategoryProperties">
        <div class="form-group">
          <label>{{ pv.property.label }}</label>
          <b-form-select
              v-model="values[pv.property_id]"
              :options="variants(pv)"/>
        </div>
      </div>
    </div>

    <div class="text-right">
      <b-button type="submit" variant="primary">Сохранить</b-button>
    </div>

    <div class="mt-3 pt-2 border-top">
      <div class="row text-center">
        <div class="col-6">
          <b-button variant="link" @click="show_add_property = !show_add_property">
            <small>Добавить свойство в категорию</small>
          </b-button>
          <b-form-select size="sm" v-if="show_add_property" v-model="add_property" :options="getPropertiesOptions"/>
          <b-button-group size="sm" class="mt-2" v-if="add_property">
            <b-button variant="success" @click="onAddProperty">Добавить</b-button>
            <b-button @click="add_property = null">Отмена</b-button>
          </b-button-group>
        </div>
        <div class="col-6">
          <b-button variant="link" @click="show_del_property = !show_del_property">
            <small>
              Удалить свойство из категории
            </small>
          </b-button>
          <b-form-select size="sm" v-if="show_del_property" v-model="del_property"
                         :options="getCategoryPropertiesOptions"/>
          <b-button-group size="sm" class="mt-2" v-if="del_property">
            <b-button variant="success" @click="onDelProperty">Удалить</b-button>
            <b-button @click="del_property = null">Отмена</b-button>
          </b-button-group>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'ModalPropertyValues',
    computed: {
      ...mapGetters([
        'getModalData',
        'getCategoryProperties',
        'getPropertiesOptions',
        'getCategoryPropertiesOptions',
      ]),
    },
    data() {
      return {
        show_add_property: false,
        show_del_property: false,
        add_property: null,
        del_property: null,
        values: {}
      }
    },
    mounted() {
      this.queryProperties()
      this.queryCategoryProperties(this.getModalData.category_id)
      this.values = {}

      let property_values = this.getModalData.property_values
      for (let property_value of property_values)
        this.values[property_value.property.property_id] = property_value.value ? property_value.value : null
    },
    methods: {
      ...mapActions([
        'queryProperties',
        'queryCategoryProperties',
        'propertyCategory',
        'savePropertyValues',
        'setToast',
      ]),
      variants(pv) {
        if (pv.property.variants) {
          let arr = pv.property.variants.split("\n")
          arr.unshift('Не указано')
          return arr
        }
        return []
      },
      onAddProperty() {
        this.propertyCategory({property_id: this.add_property, category_id: this.getModalData.category_id, type: 'add'})
        this.add_property = null
      },
      onDelProperty() {
        this.propertyCategory({property_id: this.del_property, category_id: this.getModalData.category_id, type: 'del'})
        this.del_property = null
      },
      onSubmit() {
        let data = {
          product_id: this.getModalData.id,
          category_id: this.getModalData.category_id,
          values: []
        }
        for (let item in this.values)
          data.values.push({id: item, value: this.values[item]})

        this.savePropertyValues(data);
      }
    }
  }
</script>

<style>

</style>