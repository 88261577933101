<template>
  <form class="form" @submit.prevent="onSubmit">
    <div class="form-group">
      <label>Название*</label>
      <input type="text" class="form-control" v-model="form.name"/>
    </div>
    <div class="form-group">
      <label v-if="getModalData.id">Изображение</label>
      <label v-else>Изображение*</label>
      <div class="custom-file">
        <input type="file" class="custom-file-input" id="customFile" ref="image"
               @change="filename = $refs.image.files[0].name">
        <label class="custom-file-label" for="customFile">{{ filename }}</label>
      </div>
      <a :href="getModalData.image" target="_blank" v-if="getModalData.image">Текущее изображение</a>
    </div>
    <div class="form-group">
      <label>Порядковый номер</label>
      <input type="text" class="form-control" v-model="form.sort"/>
    </div>
    <div class="form-group">
      <label>Meta Заголовок</label>
      <input type="text" class="form-control" v-model="form.meta_title"/>
    </div>
    <div class="form-group">
      <label>Meta Описание</label>
      <input type="text" class="form-control" v-model="form.meta_description"/>
    </div>
    <div class="form-group">
      <label>Meta Ключевые фразы</label>
      <input type="text" class="form-control" v-model="form.meta_keywords"/>
    </div>
    <div class="form__btn">
      <b-button type="submit" variant="primary" v-if="getModalData.id">Сохранить</b-button>
      <b-button type="submit" variant="primary" v-else>Добавить</b-button>
    </div>
  </form>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'ModalCategory',
    computed: {
      ...mapGetters([
        'getModalData',
        'getCategoriesOptions',
      ]),
    },
    data() {
      return {
        form: {
          name: '',
          sort: 0,
          meta_title: '',
          meta_description: '',
          meta_keywords: '',
        },
        filename: null,
      }
    },
    mounted() {
      if (this.getModalData.id) {
        this.form = {
          name: this.getModalData.name,
          sort: this.getModalData.sort,
          meta_title: this.getModalData.meta_title,
          meta_description: this.getModalData.meta_description,
          meta_keywords: this.getModalData.meta_keywords,
        }
      }
    },
    methods: {
      ...mapActions([
        'storeCategory',
        'updateCategory',
        'setToast',
      ]),
      onSubmit() {
        let formData = new FormData()
        if (this.$refs.image.files[0])
          formData.append('image', this.$refs.image.files[0])
        formData.append('name', this.form.name)
        formData.append('sort', this.form.sort)
        formData.append('meta_title', this.form.meta_title)
        formData.append('meta_description', this.form.meta_description)
        formData.append('meta_keywords', this.form.meta_keywords)

        if (this.getModalData.id)
          this.updateCategory([this.getModalData.id, formData])
        else
          this.storeCategory(formData)
      },
    }
  }
</script>

<style scoped>

</style>