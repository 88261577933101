import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

import preloader from './preloader'
import toast from './toast'
import modals from './modals'

import auth from './auth'
import cart from './cart'

import catalog from './catalog'
import categories from './categories'
import collections from './collections'
import users from './users'
import settings from './settings'
import products from './products'
import properties from './properties'
import orders from './orders'
import slides from './slides'
import pages from './pages'
import reviews from './reviews'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    preloader,
    toast,
    modals,

    auth,
    cart,

    catalog,
    categories,
    collections,
    users,
    settings,
    products,
    properties,
    orders,
    slides,
    pages,
    reviews,
  },
  plugins: [
    createPersistedState({
      paths: ['auth', 'cart']
    })
  ],
})