<template>
  <div class="collection">
    <h1>Коллекция {{ collection.name }}</h1>
    <div>
      <b-container>
        <b-row class="py-3">
          <b-col cols="12" lg="12">
            <ProductsGrid :collection="true"/>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex'

  // import Sidebar from './Sidebar'
  import ProductsGrid from './ProductsGrid'

  export default {
    name: 'Collection',
    components: {
      // Sidebar,
      ProductsGrid,
    },
    props: ['slug'],
    computed: {
      ...mapGetters([
        'getCollections',
      ]),
      collection() {
        for (let collection of this.getCollections) {
          if (collection.slug === this.slug) {
            this.setCatalog(collection.products)

            return collection
          }
        }
      }
    },
    mounted() {
      this.queryCollections()
    },
    methods: {
      ...mapActions([
        'queryCollections',
      ]),
      ...mapMutations([
        'setCatalog',
      ])
    }
  }
</script>

<style lang="scss">
  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>
