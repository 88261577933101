<template>
  <div id="yookassa">
    <h1>Статус оплаты заказ № {{ $route.params.order }}</h1>
    <div class="border-top pt-3">
      <b-container>
        <div v-html="status" class="h3"></div>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Yookassa',
  data() {
    return {
      status: null
    }
  },
  async beforeMount() {
    document.title = 'Статус платежа. Смесители Krone в Москве. Аксессуары. Доставка по России.'
  },
  mounted() {
    axios
        .get(process.env.VUE_APP_API_ENDPOINT + 'orders/' + this.$route.params.order)
        .then(response => (this.status = response.data.status));
  },
}
</script>

<style>
#page .container {
  text-align: initial
}
</style>