<template>
  <div class="hello">
    <h1>Каталог KRONE</h1>
    <div>
      <b-container>
        <div class="border-top border-bottom">
          <b-row class="py-3">
            <b-col cols="3" style="line-height: 38px;">
              Найдено товаров: <span class="text-info">{{ getCatalog.length }}</span>
            </b-col>
            <b-col cols="9">
              <div class="text-right">
                Сортировка:
                <b-form-select class="w-auto ml-2" v-model="sort" :options="getSortOptions"/>
                <div class="ml-3 d-inline-block d-lg-none" @click="toggleSidebarStatus">
                  <b-button variant="light">
                    <b-icon icon="ui-checks"/>
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-row class="py-3">
          <b-col cols="12" lg="3">
            <Sidebar/>
          </b-col>
          <b-col cols="12" lg="9">
            <ProductsGrid/>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex'

  import Sidebar from './Sidebar'
  import ProductsGrid from './ProductsGrid'

  export default {
    name: 'Catalog',
    components: {
      Sidebar,
      ProductsGrid,
    },
    props: {
      msg: String
    },
    data() {
      return {
        sort: null
      }
    },
    computed: {
      ...mapGetters([
        'getSidebarStatus',
        'getCatalog',
        'getSortOptions',
        'getSort',
      ])
    },
    watch: {
      'sort'(val) {
        this.setSort(val)
        this.queryCatalog()
      }
    },
    mounted() {
      this.querySettings()
      this.queryCategories()

      this.sort = this.getSort ? this.getSort : this.getSortOptions[0].value
    },
    methods: {
      ...mapActions([
        'querySettings',
        'queryCategories',
        'queryCatalog',
      ]),
      ...mapMutations([
        'setSort',
        'toggleSidebarStatus',
      ])
    }
  }
</script>

<style lang="scss">
  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>
