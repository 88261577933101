import axios from 'axios'

export default {
  state: {
    list: [],
  },
  mutations: {
    addToCart(state, payload) {
      let isset = false
      for (let item in state.list) {
        if (payload.id == state.list[item].id) {
          isset = item
          break
        }
      }

      if (isset) {
        state.list[isset].count++
      } else {
        state.list.push({
          id: payload.id,
          photo: payload.photos[0].photo,
          title: payload.title,
          url: '/product/' + payload.slug,
          price: payload.price,
          count: 1,
          summ: payload.price,
        })
      }
    },
    changeCartCount(state, payload) {
      for (let item in state.list) {
        if (item == payload[1]) {
          state.list[item].count = payload[0]
          break
        }
      }
    },
    removeFromCart(state, payload) {
      state.list.splice(payload, 1);
    },
    clearCart(state) {
      state.list = []
    }
  },
  actions: {
    addToCart({dispatch, commit, state}, payload) {
      dispatch('setToast', {status: 'success', message: 'Добавлено!'})
      commit('addToCart', payload)
    },
    queryCreateOrder({dispatch, commit, state}, payload) {
      dispatch('setPreloader', true)
      payload.cart = state.list
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'order', payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('closeModal')
          commit('clearCart')
          dispatch('setToast', {status: 'success', message: res.data.message})
        })
    },
    queryCreateOrderPayment({dispatch, commit, state}, payload) {
      dispatch('setPreloader', true)
      payload.cart = state.list
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'order', payload)
        .then(res => {
          dispatch('closeModal')
          commit('clearCart')
          dispatch('setToast', {status: 'success', message: res.data.message})
          setTimeout(function () {
            window.location.replace(res.data.payment_url);
          }, 2000)
        })
    },
  },
  getters: {
    getCart: state => {
      return state.list
    },
    getCartTotal: (state, rootGetters) => {
      let total = 0

      for (let item of state.list) {
        total += item.price * item.count
      }

      return total + rootGetters.getDelivery
    },
  }
}