import axios from 'axios'

export default {
  state: {
    list: [],
  },
  mutations: {
    setSettings(state, payload) {
      state.list = payload
    },
  },
  actions: {
    querySettings({dispatch, commit}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'settings')
        .then(res => {
          dispatch('setPreloader', false)
          commit('setSettings', res.data)
        })
    },
    storeSetting({commit, dispatch}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'settings', payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('querySettings')
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    updateSetting({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'settings/' + payload[0], payload[1])
        .then(res => {
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('querySettings')
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
  },
  getters: {
    getSettings: state => {
      return state.list
    },
    getPhone: state => {
      for (let item of state.list) {
        if (item.id === 'phone')
          return item.value
      }
      return ''
    },
    getDelivery: state => {
      for (let item of state.list) {
        if (item.id === 'delivery')
          return parseInt(item.value)
      }
      return ''
    },
  }
}