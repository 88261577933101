<template>
  <form class="form" @submit.prevent="onSubmit">
    <div class="form-group">
      <label>Заголовок</label>
      <input type="text" class="form-control" v-model="form.title"/>
    </div>
    <div class="form-group">
      <label>Содержимое</label>
      <quill-editor v-model="form.body" />
    </div>
    <div class="form-group">
      <label>Meta Заголовок</label>
      <input type="text" class="form-control" v-model="form.meta_title"/>
    </div>
    <div class="form-group">
      <label>Meta Описание</label>
      <input type="text" class="form-control" v-model="form.meta_description"/>
    </div>
    <div class="form-group">
      <label>Meta Ключевые фразы</label>
      <input type="text" class="form-control" v-model="form.meta_keywords"/>
    </div>
    <div class="form__btn">
      <b-button type="submit" variant="primary" v-if="getModalData.id">Сохранить</b-button>
      <b-button type="submit" variant="primary" v-else>Добавить</b-button>
    </div>
  </form>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import { quillEditor } from 'vue-quill-editor'

  export default {
    name: 'ModalPage',
    components: {
      quillEditor
    },
    computed: {
      ...mapGetters([
        'getModalData',
        'getRoles',
      ]),
    },
    data() {
      return {
        form: {
          title: '',
          body: '',
          meta_title: '',
          meta_description: '',
          meta_keywords: '',
        },
      }
    },
    mounted() {
      if (this.getModalData.id) {
        this.form = {
          title: this.getModalData.title,
          body: this.getModalData.body,
          meta_title: this.getModalData.meta_title,
          meta_description: this.getModalData.meta_description,
          meta_keywords: this.getModalData.meta_keywords,
        }
      }
    },
    methods: {
      ...mapActions([
        'storePage',
        'updatePage',
        'setToast',
      ]),
      onSubmit() {
        if (this.getModalData.id)
          this.updatePage([this.getModalData.id, this.form])
        else
          this.storePage(this.form)
      },
    },
  }
</script>

<style scoped>

</style>