<template>
  <div id="categories">
    <h1>Настройки</h1>

    <table class="table bg-light">
      <thead>
      <tr>
        <th>id</th>
        <th>Параметр</th>
        <th>Значение</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="setting of getSettings">
        <td>{{ setting.id }}</td>
        <td>{{ setting.name }}</td>
        <td>{{ setting.value }}</td>
        <td>
          <b-icon icon="pencil-square" class="mx-2 cursor_pointer" variant="success" @click="onShowEditModal(setting)"/>
          <b-icon icon="trash" class="cursor_pointer" variant="danger" @click="onDelete(setting)"/>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="4">
          <b-button variant="link" class="cursor_pointer p-0" @click="onShowCreateModal">Добавить параметр</b-button>
        </td>
      </tr>
      </tfoot>
    </table>

  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'Settings',
    computed: {
      ...mapGetters([
        'getSettings',
      ])
    },
    mounted() {
      this.querySettings()
    },
    methods: {
      ...mapActions([
        'querySettings',
        'destroySetting',
        'showModal',
      ]),
      onShowCreateModal() {
        this.showModal({modal: 'ModalSetting', title: 'Добавление параметра', data: {}})
      },
      onShowEditModal(setting) {
        this.showModal({modal: 'ModalSetting', title: 'Редактирование параметра', data: setting})
      },
    }
  }
</script>

<style>

</style>