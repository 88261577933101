<template>
  <div id="products">
    <h1>Товары</h1>

    <table class="table bg-light">
      <thead>
        <tr>
          <th colspan="6">
            <div class="form-group">
              <label>Категория товаров:</label>
              <b-form-select v-model="category_id" :options="getCategoriesOptionsAll"/>
            </div>
          </th>
        </tr>
        <tr>
          <th>id</th>
          <th>Фото</th>
          <th>Наименование/Арткул</th>
          <th>Цена</th>
          <th>Наличие</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      <tr v-for="product of getProducts">
        <td>{{ product.id }}</td>
        <td>
          <div v-if="product.photos.length">
            <img :src="product.photos[0].photo" style="height: 100px">
          </div>
          <div><b-button variant="link" @click="onShowEditModalPhotos(product)">изменить</b-button></div>
        </td>
        <td>{{ product.title }}<br>{{ product.article }}<div v-if="product.new"><b>new</b></div></td>
        <td>{{ product.price }} руб.</td>
        <td>{{ product.stock }} штук</td>
        <td>
          <b-button-group size="sm" vertical>
            <b-button variant="success" @click="onShowEditModal(product)">Редактировать</b-button>
            <b-button variant="warning" @click="onShowPropertiesModal(product)">Свойства</b-button>
            <b-button variant="danger" @click="onDelete(product)">Удалить</b-button>
          </b-button-group>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="6">
          <b-button variant="link" class="cursor_pointer p-0" @click="onShowCreateModal">Добавить товар</b-button>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex'

  export default {
    name: 'Products',
    data() {
      return {
        category_id: null
      }
    },
    computed: {
      ...mapGetters([
        'getCategoryId',
        'getProducts',
        'getCategoriesOptionsAll',
      ])
    },
    watch: {
      'category_id'(val) {
        this.setCategoryId(val)
        this.queryProducts()
      }
    },
    mounted() {
      this.queryCategories()
      this.queryCollections()
    },
    methods: {
      ...mapActions([
        'queryCategories',
        'queryCollections',
        'queryProducts',
        'destroyProduct',
        'showModal',
      ]),
      ...mapMutations([
        'setProductForm',
        'setCategoryId'
      ]),
      onShowCreateModal() {
        this.showModal({modal: 'ModalProduct', title: 'Добавление товара', data: {}})
      },
      onShowEditModal(product) {
        this.showModal({modal: 'ModalProduct', title: 'Редактирование товара', data: product})
      },
      onShowEditModalPhotos(product) {
        this.setProductForm(product)
        this.showModal({modal: 'ModalProductPhotos', title: 'Фото товара "' + product.title + '"', data: product})
      },
      onShowPropertiesModal(product) {
        this.showModal({modal: 'ModalPropertyValues', title: 'Свойства товара', size: 'lg', data: product})
      },
      onDelete(product) {
        this.$bvModal.msgBoxConfirm('Удалить товар "' + product.title + '"?')
          .then(value => {
            if (value)
              this.destroyProduct(product.id)
          })
      }
    }
  }
</script>

<style scoped>

</style>