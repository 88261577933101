export default {
  state: {
    status: false,
    modal: null,
    title: null,
    size: null,
    data: {},
  },
  mutations: {
    setModal(state, payload) {
      state.status = true
      state.modal = payload.modal
      state.title = payload.title
      state.size = payload.size ? payload.size : 'md'
      state.data = payload.data
    },
    setTitle(state, payload) {
      state.title = payload
    },
    clearModal(state) {
      state.status = false
      state.modal = null
      state.title = null
      state.size = null
      state.data = {}
    },
  },
  actions: {
    showModal({commit}, payload) {
      commit('setModal', payload)
    },
    closeModal({commit}) {
      commit('clearModal')
    },
  },
  getters: {
    getModalStatus: state => {
      return state.status
    },
    getModal: state => {
      return state.modal
    },
    getModalTitle: state => {
      return state.title
    },
    getModalSize: state => {
      return state.size
    },
    getModalData: state => {
      return state.data
    },
  }
}