<template>
  <form class="form" @submit.prevent="onSubmit">
    <div class="form-group">
      <label>ID</label>
      <input type="text" class="form-control" :disabled="getModalData.id" v-model="form.id"/>
    </div>
    <div class="form-group">
      <label>Название</label>
      <input type="text" class="form-control" :disabled="getModalData.id" v-model="form.name"/>
    </div>
    <div class="form-group">
      <label>Значение</label>
      <textarea type="text" class="form-control" v-model="form.value"></textarea>
    </div>
    <div class="form__btn">
      <b-button type="submit" variant="primary" v-if="getModalData.id">Сохранить</b-button>
      <b-button type="submit" variant="primary" v-else>Добавить</b-button>
    </div>
  </form>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'ModalCategory',
    computed: {
      ...mapGetters([
        'getModalData',
      ]),
    },
    data() {
      return {
        form: {
          id: '',
          name: '',
          value: '',
        },
      }
    },
    mounted() {
      if (this.getModalData.id) {
        this.form = {
          id: this.getModalData.id,
          name: this.getModalData.name,
          value: this.getModalData.value
        }
      }
    },
    methods: {
      ...mapActions([
        'storeSetting',
        'updateSetting',
        'setToast',
      ]),
      onSubmit() {
        if (this.getModalData.id)
          this.updateSetting([this.getModalData.id, this.form])
        else
          this.storeSetting(this.form)
      },
    }
  }
</script>

<style scoped>

</style>