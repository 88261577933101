<template>
  <form class="form" @submit.prevent="onSubmit">
    <div class="form-group">
      <label>Имя*</label>
      <input type="text" class="form-control" v-model="form.label"/>
    </div>
    <div class="form-group">
      <label>Тип поля*</label>
      <b-form-select v-model="form.type" :options="getPropertyTypes"/>
    </div>
    <div class="form-group" v-if="form.type === 'num'">
      <label>Мера</label>
      <input type="text" class="form-control" v-model="form.unit" placeholder="Например мм"/>
    </div>
    <div class="form-group" v-if="form.type === 'list'">
      <label>Варианты (построчно)</label>
    <textarea class="form-control" v-model="form.variants"></textarea>
    </div>
    <div class="form-group">
      <label>Порядковый номер</label>
      <input type="text" class="form-control" v-model="form.sort"/>
    </div>
    <div class="form__btn">
      <b-button type="submit" variant="primary" v-if="getModalData.id">Сохранить</b-button>
      <b-button type="submit" variant="primary" v-else>Добавить</b-button>
    </div>
  </form>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'ModalProperty',
    computed: {
      ...mapGetters([
        'getModalData',
        'getPropertyTypes',
      ]),
    },
    data() {
      return {
        form: {
          label: '',
          unit: '',
          type: 'num',
          sort: 0,
        },
      }
    },
    mounted() {
      if (this.getModalData.id) {
        this.form = {
          label: this.getModalData.label,
          unit: this.getModalData.unit,
          type: this.getModalData.type,
          sort: this.getModalData.sort,
        }
      }
    },
    methods: {
      ...mapActions([
        'storeProperty',
        'updateProperty',
        'setToast',
      ]),
      onSubmit() {
        if (this.getModalData.id)
          this.updateProperty([this.getModalData.id, this.form])
        else
          this.storeProperty(this.form)
      },
    }
  }
</script>

<style scoped>

</style>