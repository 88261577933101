<template>
  <div id="collections">
    <h1>Коллекции</h1>

    <ul class="list-group text-left">
      <template v-for="collection of getCollections">
        <li class="list-group-item">
          <img :src="collection.photo" :alt="collection.name" style="height: 50px">
          {{ collection.name }}
          <b-icon icon="pencil-square" class="mx-2 cursor_pointer" variant="success" @click="onShowEditModal(collection)"/>
          <b-icon icon="trash" class="cursor_pointer" variant="danger" @click="onDelete(collection)"/>
        </li>
      </template>
      <li class="list-group-item">
        <b-button variant="link" class="cursor_pointer p-0" @click="onShowCreateModal">Добавить коллекцию</b-button>
      </li>
    </ul>

  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'Collections',
    computed: {
      ...mapGetters([
        'getCollections',
      ])
    },
    mounted() {
      this.queryCollections()
    },
    methods: {
      ...mapActions([
        'queryCollections',
        'destroyCollection',
        'showModal',
      ]),
      onShowCreateModal() {
        this.showModal({modal: 'ModalCollection', title: 'Добавление коллекции', data: {}})
      },
      onShowEditModal(collection) {
        this.showModal({modal: 'ModalCollection', title: 'Редактирование коллекции', data: collection})
      },
      onDelete(collection) {
        this.$bvModal.msgBoxConfirm('Удалить коллекцию "' + collection.name + '"?')
          .then(value => {
            this.destroyCollection(collection.id)
          })
      }
    }
  }
</script>

<style>

</style>