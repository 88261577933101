import axios from 'axios'

export default {
  state: {
    list: [],
  },
  mutations: {
    setOrders(state, payload) {
      state.list = payload
    },
  },
  actions: {
    queryOrders({dispatch, commit}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'orders')
        .then(res => {
          dispatch('setPreloader', false)
          commit('setOrders', res.data)
        })
    },
    updateOrder({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'orders/' + payload[0], payload[1])
        .then(res => {
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('queryOrders')
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
  },
  getters: {
    getOrders: state => {
      return state.list
    },
  }
}