import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home'
import Catalog from '../components/catalog/Catalog'
import Collection from '../components/catalog/Collection'
import Product from '../components/catalog/Product'

import Yookassa from "@/components/Yookassa.vue"
import Profile from '../components/Profile'
import Reviews from '../components/Reviews'
import Dashboard from '../components/admin/Dashboard'
import Page from '../components/Page'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/yookassa/:order',
    name: 'Yookassa',
    component: Yookassa,
    props: true,
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: Catalog
  },
  {
    path: '/сollections/:slug',
    name: 'Collection',
    component: Collection,
    props: true,
  },
  {
    path: '/product/:slug',
    name: 'Product',
    component: Product,
    props: true,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: Reviews
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/page/:slug',
    name: 'Page',
    component: Page,
    props: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
