<template>
  <footer id="footer" class="text-light py-3">
    <b-container>
      <b-row>
        <b-col cols="12" lg="3" class="d-flex justify-content-center justify-content-lg-start align-items-center mb-3 mb-lg-0">
          <img src="/flag.png" style="height: 35px; margin-right: 20px;" alt="Произведено в Германии">
          <a href="/">
            <img src="/logo.png" alt="Krone">
          </a>
        </b-col>
        <b-col col class="d-flex justify-content-center align-items-center mb-3 mb-lg-0">
          <div>© 2023 KRONE. НЕМЕЦКОЕ КАЧЕСТВО.<br>ВСЕ ПРАВА ЗАЩИЩЕНЫ</div>
        </b-col>
        <b-col cols="12" lg="3"></b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
  export default {
    name: 'Footer'
  }
</script>

<style>
  #footer {
    background: #343a40;
  }
</style>