<template>
  <div id="slides">
    <h1>Слайды</h1>

    <table class="table bg-light">
      <thead>
      <tr>
        <th>Изображение</th>
        <th>Текст</th>
        <th>Ссылка</th>
        <th>Статус</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="slide of getSlides">
          <td>
            <img :src="slide.image" :alt="slide.name" style="height: 50px">
          </td>
          <td>
            <div class="font-weight-bold">{{ slide.title }}</div>
            <div>{{ slide.text }}</div>
          </td>
          <td>
            <a :href="slide.link" v-if="slide.link" target="_blank">{{ slide.link }}</a>
          </td>
          <td>{{ slide.shown ? 'Вкл' : 'Выкл' }}</td>
          <td>
            <b-icon icon="pencil-square" class="mx-2 cursor_pointer" variant="success" @click="onShowEditModal(slide)"/>
            <b-icon icon="trash" class="cursor_pointer" variant="danger" @click="onDelete(slide)"/>
          </td>
        </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="5">
          <b-button variant="link" class="cursor_pointer p-0" @click="onShowCreateModal">Добавить слайд</b-button>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'Slides',
    computed: {
      ...mapGetters([
        'getSlides',
      ])
    },
    mounted() {
      this.querySlides()
    },
    methods: {
      ...mapActions([
        'querySlides',
        'destroySlide',
        'showModal',
      ]),
      onShowCreateModal() {
        this.showModal({modal: 'ModalSlide', title: 'Добавление слайда', data: {}})
      },
      onShowEditModal(slide) {
        this.showModal({modal: 'ModalSlide', title: 'Редактирование слайда', data: slide})
      },
      onDelete(slide) {
        this.$bvModal.msgBoxConfirm('Удалить слайд?')
          .then(value => {
            this.destroySlide(slide.id)
          })
      }
    }
  }
</script>

<style>

</style>