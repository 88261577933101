import axios from 'axios'

export default {
  state: {
    list: [],
  },
  mutations: {
    setReviews(state, payload) {
      state.list = payload
    },
  },
  actions: {
    queryReviews({dispatch, commit}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'reviews')
        .then(res => {
          dispatch('setPreloader', false)
          commit('setReviews', res.data)
        })
    },
    storeReview({commit, dispatch}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'reviews', payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('setPreloader', false)
          dispatch('queryReviews')
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    destroyReview({dispatch, commit}, review_id) {
      dispatch('setPreloader', true)
      axios.delete(process.env.VUE_APP_API_ENDPOINT + 'reviews/' + review_id)
        .then(res => {
          dispatch('queryReviews')
          dispatch('setToast', {status: 'success', message: res.data})
          dispatch('setPreloader', false)
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
  },
  getters: {
    getReviews: state => {
      return state.list
    },
  }
}