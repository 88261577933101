<template>
  <div id="profile">
    <h1>Профиль</h1>
    <div class="border-top">
      <b-container>
        <b-row class="py-3">
          <b-col cols="3">
            <div class="bg-grey p-3">
              {{ getAuthUser.name }}
            </div>
            <div class="bg-light">
              <b-list-group>
                <b-list-group-item
                        class="border-0"
                        button
                        :active="activeTab == 1"
                        @click="activeTab = 1">
                  Мои заказы
                </b-list-group-item>
                <b-list-group-item
                        v-if="getAuthUser.role == 'admin' || getAuthUser.role == 'dealer'"
                        class="border-0"
                        button :active="activeTab == 2"
                        @click="activeTab = 2">
                  Дилерство
                </b-list-group-item>
                <b-list-group-item
                        class="border-0"
                        button :active="activeTab == 3"
                        @click="activeTab = 3">
                  Настройки
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
          <b-col cols="9">
            <div class="bg-light p-3">
              <template v-if="activeTab == 1">
                <div id="orders">
                  <h1>Список заказов</h1>
                  <table class="table table-striped bg-light">
                    <thead>
                    <tr>
                      <th scope="col">№</th>
                      <th scope="col" class="text-center">Статус</th>
                      <th scope="col" class="text-center">Дата</th>
                      <th scope="col" class="text-center">Заказ</th>
                      <th scope="col" class="text-center">Доставка</th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item of getOrders">
                      <td>{{ item.id }}</td>
                      <td>
                        <div>{{ item.status }}</div>
                        <template v-if="item.status === 'Ожидает оплаты'">
                          <a :href="item.yookassa_url" target="_blank">Оплатить</a>
                        </template>
                      </td>
                      <td>{{ item.created_at }}</td>
                      <td class="text_small">
                        <div v-for="product of item.products" class="border-bottom">
                          <div>{{ product.title }} <span class="text-muted">({{ product.article }})</span></div>
                          <div>{{ product.price }} руб. - {{ product.count }} шт.</div>
                        </div>
                        <div class="font-weight-bold">Итого: {{ item.summ }} руб.</div>
                      </td>
                      <td class="text_small">
                        <div>{{ item.name }}</div>
                        <div>{{ item.address }}</div>
                        <div>{{ item.phone }}</div>
                        <div>{{ item.email }}</div>
                      </td>
                      <td></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template v-if="activeTab == 2 && (getAuthUser.role == 'admin' || getAuthUser.role == 'dealer')">
                <h3 class="mt-0 mb-3">Ваша персональная скидка: {{ getAuthUser.discount }}%</h3>
                <b-form @submit.prevent="onSaveCompany" class="text-left">
                      <b-form-group label="Название компании:">
                        <b-form-input
                                v-model="formCompany.company"
                                type="text"
                                required
                                placeholder="Введите название компании"/>
                      </b-form-group>

                      <b-form-group label="ИНН:">
                        <b-form-input
                                v-model="formCompany.inn"
                                type="text"
                                required
                                placeholder="Введите инн"/>
                      </b-form-group>
                      <b-form-group label="Юридический адрес:">
                        <b-form-input
                                v-model="formCompany.address"
                                type="text"
                                required
                                placeholder="Введите адрес"/>
                      </b-form-group>

                  <b-button variant="primary" type="submit">Сохранить</b-button>
                </b-form>
              </template>
              <template v-if="activeTab == 3">
                <b-form @submit.prevent="onSaveProfile">
                  <b-row>
                    <b-col cols="6">
                      <b-form-group label="ФИО:">
                        <b-form-input
                                v-model="formProfile.name"
                                type="text"
                                required
                                placeholder="Введите имя"/>
                      </b-form-group>

                      <b-form-group label="Email:">
                        <b-form-input
                                v-model="formProfile.email"
                                type="email"
                                required
                                placeholder="Введите email"/>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Email:">
                        <b-form-input
                                v-model="formProfile.phone"
                                type="text"
                                required
                                placeholder="Введите телефон"/>
                      </b-form-group>

                      <b-form-group label="Пароль:">
                        <b-form-input
                                v-model="formProfile.password"
                                type="text"
                                autocomplete="off"
                                placeholder="Введите новый пароль"/>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-button variant="primary" type="submit">Сохранить</b-button>
                </b-form>
              </template>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'Profile',
    data() {
      return {
        activeTab: 1,
        formProfile: {
          name: '',
          email: '',
          phone: '',
          password: '',
        },
        formCompany: {
          company: '',
          inn: '',
          address: '',
        }
      }
    },
    mounted() {
      this.queryOrders()

      this.formProfile.name = this.getAuthUser.name
      this.formProfile.email = this.getAuthUser.email
      this.formProfile.phone = this.getAuthUser.phone
      this.formCompany.company = this.getAuthUser.company
      this.formCompany.inn = this.getAuthUser.inn
      this.formCompany.address = this.getAuthUser.address
    },
    computed: {
      ...mapGetters([
        'getAuthUser',
        'getOrders'
      ])
    },
    methods: {
      ...mapActions([
        'queryOrders',
        'updateProfile',
      ]),
      onSaveProfile() {
        this.updateProfile([this.getAuthUser.id, this.formProfile])
      },
      onSaveCompany() {
        this.updateProfile([this.getAuthUser.id, this.formCompany])
      }
    }
  }
</script>

<style>

</style>