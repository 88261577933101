<template>
  <div id="sidebar" class="text-left">
    <div class="d-none d-lg-block">
      <div class="filter p-3">
        <div class="filter_label border-bottom pb-2 mb-2">Категория:</div>
        <template v-for="category of getCategories">
          <b-button :variant="filters.category == category.slug ? 'dark' : 'outline-dark'"
                    class="w-100 mb-2"
                    @click="setCategory(category)">
            {{ category.name }}
          </b-button>
        </template>
      </div>

      <template v-for="filter of getFilters">
        <div class="filter p-3" v-if="filter.property">
          <div class="filter_label border-bottom pb-2 mb-2">{{ filter.property.label }}:</div>
          <template v-for="variant of variants(filter.property)">
            <b-form-checkbox
                v-model="filters[variant.id]"
                :value="variant.value"
                @change="onChangeFilters(variant.id, variant.value, $event)">
              {{ variant.value }}
            </b-form-checkbox>
          </template>
        </div>
      </template>
    </div>

    <div class="d-block d-lg-none">
      <b-sidebar title="Фильтры" shadow :visible="getSidebarStatus">
        <div class="filter p-3">
          <div class="filter_label border-bottom pb-2 mb-2">Категория:</div>
          <template v-for="category of getCategories">
            <b-button :variant="filters.category == category.slug ? 'dark' : 'outline-dark'"
                      class="w-100 mb-2"
                      @click="setCategory(category)">
              {{ category.name }}
            </b-button>
          </template>
        </div>

        <template v-for="filter of getFilters">
          <div class="filter p-3" v-if="filter.property">
            <div class="filter_label border-bottom pb-2 mb-2">{{ filter.property.label }}:</div>
            <template v-for="variant of variants(filter.property)">
              <b-form-checkbox
                  v-model="filters[variant.id]"
                  :value="variant.value"
                  @change="onChangeFilters(variant.id, variant.value, $event)">
                {{ variant.value }}
              </b-form-checkbox>
            </template>
          </div>
        </template>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex'

  export default {
    name: 'Sidebar',
    data() {
      return {
        filters: {}
      }
    },
    computed: {
      ...mapGetters([
        'getSidebarStatus',
        'getCategories',
        'getFilters',
        'getFiltersData',
      ]),
    },
    watch: {
      'filter.category'(val) {
        this.$router.push('/catalog?category=' + val)
        this.setFiltersData(this.filters)
        this.queryFilters()
        this.$forceUpdate()
      },
      '$route.query.category'(val) {
        this.filters.category = val
        this.setFiltersData(this.filters)
        this.queryFilters()
        this.queryCatalog()
        this.$forceUpdate()
      }
    },
    mounted() {
      this.filters.category = this.$route.query.category
      this.setFiltersData(this.filters)
      this.queryFilters()
      this.queryCatalog()
    },
    methods: {
      ...mapActions([
        'queryCatalog',
        'queryFilters',
      ]),
      ...mapMutations([
        'setSidebarStatus',
        'setFilters',
        'setFiltersData',
      ]),
      setCategory(category) {
        this.filters.category = category.slug
        this.setFiltersData(this.filters)
        this.queryFilters()
        this.$forceUpdate()
        this.queryCatalog()
      },
      onChangeFilters(prop_id, value, event) {
        this.filters[prop_id] = event ? value : null
        this.queryCatalog(this.filters)
        this.$forceUpdate()
      },
      variants(property) {
        let arr = []
        for (let item of property.variants.split("\n")) {
          arr.push({
            id: property.id,
            value: item
          })
        }
        return arr
      }
    }
  }
</script>

<style lang="scss">
  #sidebar {
    background: #fff;

    .filter_label {
      font-family: 'Open Sans', Arial, sans-serif;
      font-weight: bold;
      font-size: 13px;
      line-height: 20px;
      color: #808080;
      text-transform: uppercase;
    }

    .filter {
      .filter_input {
        .filter_type_checkbox {
          label {
            cursor: pointer;
          }
        }
      }
    }
  }
</style>