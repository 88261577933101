import axios from 'axios'

export default {
  state: {
    list: [],
    roles: ['client', 'dealer', 'admin']
  },
  mutations: {
    setUsers(state, payload) {
      state.list = payload
    },
  },
  actions: {
    queryUsers({dispatch, commit}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'users')
        .then(res => {
          dispatch('setPreloader', false)
          commit('setUsers', res.data)
        })
    },
    storeUser({commit, dispatch}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'users', payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('queryUsers')
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    updateUser({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'users/' + payload[0], payload[1])
        .then(res => {
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('queryUsers')
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
    updateProfile({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'users/' + payload[0], payload[1])
        .then(res => {
          dispatch('setToast', {status: 'success', message: res.data.message})
          dispatch('closeModal', true)
          dispatch('setPreloader', false)
          dispatch('queryProfile')
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
    destroyUser({dispatch, commit}, user_id) {
      dispatch('setPreloader', true)
      axios.delete(process.env.VUE_APP_API_ENDPOINT + 'users/' + user_id)
        .then(res => {
          dispatch('queryUsers')
          dispatch('setToast', {status: 'success', message: res.data})
          dispatch('setPreloader', false)
        })
        .catch(error => {
          dispatch('setToastHttpError', error.response, {root: true})
        })
    },
  },
  getters: {
    getUsers: state => {
      return state.list
    },
    getRoles: state => {
      return state.roles
    },
  }
}