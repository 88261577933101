<template>
  <div id="login">
    <b-tabs content-class="mt-3">
      <b-tab title="Вход" active>
        <b-form @submit.prevent="onLogin">
          <b-form-group label="Email:">
            <b-form-input
                    v-model="login_form.email"
                    type="email"
                    required
                    placeholder="Введите email"/>
          </b-form-group>

          <b-form-group label="Пароль:">
            <b-form-input
                    v-model="login_form.password"
                    type="password"
                    autocomplete="off"
                    required
                    placeholder="Введите пароль"/>
          </b-form-group>
          <b-button variant="primary" type="submit">Войти</b-button>
        </b-form>
      </b-tab>
      <b-tab title="Регистрация">
        <b-form @submit.prevent="onRegister">
          <b-form-group label="ФИО:">
            <b-form-input
                    v-model="register_form.name"
                    type="text"
                    required
                    placeholder="Введите имя"/>
          </b-form-group>

          <b-form-group label="Email:">
            <b-form-input
                    v-model="register_form.email"
                    type="email"
                    required
                    placeholder="Введите email"/>
          </b-form-group>

          <b-form-group label="Пароль:">
            <b-form-input
                    v-model="register_form.password"
                    type="password"
                    autocomplete="off"
                    required
                    placeholder="Введите пароль"/>
          </b-form-group>
          <b-button variant="primary" type="submit">Зарегистрироваться и войти</b-button>
        </b-form>
      </b-tab>
      <b-tab title="Восстановить пароль">
        <b-form-group label="Email:">
          <b-form-input
                  v-model="restore_form.email"
                  type="email"
                  required
                  placeholder="Введите email"/>
        </b-form-group>
        <b-button variant="primary" @click="onRestore">Отправить</b-button>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'Modal',
    data() {
      return {
        login_form: {
          email: null,
          password: null,
        },
        register_form: {
          name: null,
          email: null,
          password: null,
        },
        restore_form: {
          email: null,
        }
      }
    },
    computed: {
      ...mapGetters([
        'getModal',
      ])
    },
    methods: {
      ...mapActions([
        'login',
        'register',
        'restorePassword',
      ]),
      onLogin() {
        this.login(this.login_form)
      },
      onRegister() {
        this.register(this.register_form)
      },
      onRestore() {
        this.restorePassword(this.restore_form)
      }
    }
  }
</script>

<style lang="scss">

</style>