<template>
  <form class="form" @submit.prevent="onSubmit">
    <div class="form-group">
      <label>Категория</label>
      <b-form-select v-model="form.category_id" :options="getCategoriesOptionsAll"/>
    </div>
    <div class="form-group">
      <label>Коллекция</label>
      <b-form-select v-model="form.collection_id" :options="getCollectionsOptions"/>
    </div>
    <div class="form-group">
      <label>Наименование</label>
      <input type="text" class="form-control" v-model="form.title"/>
    </div>
    <div class="form-group">
      <label>Артикул</label>
      <input type="text" class="form-control" v-model="form.article"/>
    </div>
    <div class="form-group">
      <label>Цена</label>
      <input type="text" class="form-control" v-model="form.price"/>
    </div>
    <div class="form-group">
      <label>Наличие в штуках</label>
      <input type="text" class="form-control" v-model="form.stock"/>
    </div>
    <div class="form-group">
      <label>Описание</label>
      <textarea class="form-control" v-model="form.description"></textarea>
    </div>
    <div class="form-group">
      <label>Новинка</label>
      <b-form-select v-model="form.new" :options="[{value: 0, text: 'Нет'}, {value: 1, text: 'Да'}]"/>
    </div>
    <div class="form-group">
      <label>Meta Заголовок</label>
      <input type="text" class="form-control" v-model="form.meta_title"/>
    </div>
    <div class="form-group">
      <label>Meta Описание</label>
      <input type="text" class="form-control" v-model="form.meta_description"/>
    </div>
    <div class="form-group">
      <label>Meta Ключевые фразы</label>
      <input type="text" class="form-control" v-model="form.meta_keywords"/>
    </div>
    <div class="form__btn">
      <b-button type="submit" variant="primary" v-if="getModalData.id">Сохранить</b-button>
      <b-button type="submit" variant="primary" v-else>Добавить</b-button>
    </div>
  </form>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'ModalProduct',
    computed: {
      ...mapGetters([
        'getModalData',
        'getCategoriesOptionsAll',
        'getCollectionsOptions',
      ]),
    },
    data() {
      return {
        form: {
          category_id: '',
          title: '',
          article: '',
          price: '',
          stock: '',
          description: '',
          new: 0,
          collection_id: '',
          meta_title: '',
          meta_description: '',
          meta_keywords: '',
        },
      }
    },
    mounted() {
      if (this.getModalData.id) {
        this.form = {
          category_id: this.getModalData.category_id,
          title: this.getModalData.title,
          article: this.getModalData.article,
          price: this.getModalData.price,
          stock: this.getModalData.stock,
          description: this.getModalData.description,
          new: this.getModalData.new,
          collection_id: this.getModalData.collection_id,
          meta_title: this.getModalData.meta_title,
          meta_description: this.getModalData.meta_description,
          meta_keywords: this.getModalData.meta_keywords,
        }
      }
    },
    methods: {
      ...mapActions([
        'storeProduct',
        'updateProduct',
        'setToast',
      ]),
      onSubmit() {
        if (this.getModalData.id)
          this.updateProduct([this.getModalData.id, this.form])
        else
          this.storeProduct(this.form)
      },
    }
  }
</script>