<template>
  <div id="categories">
    <h1>Категории</h1>

    <ul class="list-group text-left">
      <template v-for="category of getCategories">
        <li class="list-group-item">
          <img :src="category.image" :alt="category.name" style="height: 50px">
          {{ category.name }}
          <b-icon icon="pencil-square" class="mx-2 cursor_pointer" variant="success" @click="onShowEditModal(category)"/>
          <b-icon icon="trash" class="cursor_pointer" variant="danger" @click="onDelete(category)"/>
        </li>
        <ul v-if="category.childs">
          <li class="list-group-item pl-5" v-for="child of category.childs">
            <img :src="child.image" :alt="child.name" style="height: 50px">
            {{ child.name }}
            <b-icon icon="pencil-square" class="mx-2 cursor_pointer" variant="success" @click="onShowEditModal(child)"/>
            <b-icon icon="trash" class="cursor_pointer" variant="danger" @click="onDelete(child)"/>
          </li>
        </ul>
      </template>
      <li class="list-group-item">
        <b-button variant="link" class="cursor_pointer p-0" @click="onShowCreateModal">Добавить категорию</b-button>
      </li>
    </ul>

  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'Categories',
    computed: {
      ...mapGetters([
        'getCategories',
      ])
    },
    mounted() {
      this.queryCategories()
    },
    methods: {
      ...mapActions([
        'queryCategories',
        'destroyCategory',
        'showModal',
      ]),
      onShowCreateModal() {
        this.showModal({modal: 'ModalCategory', title: 'Добавление категории', data: {}})
      },
      onShowEditModal(category) {
        this.showModal({modal: 'ModalCategory', title: 'Редактирование категории', data: category})
      },
      onDelete(category) {
        this.$bvModal.msgBoxConfirm('Удалить категорию "' + category.name + '"?')
          .then(value => {
            this.destroyCategory(category.id)
          })
      }
    }
  }
</script>

<style>

</style>