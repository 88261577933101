<template>
  <div id="modalProductInfo">
    {{ text }}
  </div>
</template>

<script>
  import {mapGetters, mapMutations} from 'vuex'

  export default {
    name: 'ModalProductInfo',
    data() {
      return {
        text: null
      }
    },
    computed: {
      ...mapGetters([
        'getModalData',
        'getSettings',
      ]),
    },
    mounted() {
      for (let item of this.getSettings) {
        if (this.getModalData.tab === item.id)  {
          this.text = item.value
          this.setTitle(item.name)
          break
        }
      }
    },
    methods: {
      ...mapMutations([
        'setTitle'
      ])
    }
  }
</script>

<style>

</style>