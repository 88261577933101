<template>
  <div id="cart">
    <div v-if="getCart.length" class="overflow-auto">
      <table class="table" v-if="!order_tab">
        <thead>
        <tr>
          <th scope="col">Фото</th>
          <th scope="col">Наименовение</th>
          <th scope="col" class="text-center">Стоимость</th>
          <th scope="col" class="text-center">Количество</th>
          <th scope="col" class="text-center">Сумма</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) of getCart">
          <td><img :src="item.photo" alt="photo" style="height: 50px;"></td>
          <td>
            <router-link :to="item.url">{{ item.title }}</router-link>
          </td>
          <td class="text-center">{{ item.price }}</td>
          <td class="text-center">
            <b-form-spinbutton v-model="item.count" min="1" max="100" size="sm"
                               @change="changeCartCount([$event, index])"/>
          </td>
          <td class="text-center">{{ item.price * item.count }}</td>
          <td class="text-center">
            <b-icon icon="x-circle-fill" variant="danger" class="cursor_pointer" @click="removeFromCart(index)"/>
          </td>
        </tr>
        <tr>
          <td colspan="2">Доставка</td>
          <td colspan="4" class="text-right">{{ getDelivery }}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colspan="6" class="text-right">Итого: {{ getCartTotal }}.руб</td>
        </tr>
        </tfoot>
      </table>
      <div v-if="order_tab">
        <b-form-group
            label="ФИО:*"
            label-for="input-0">
          <b-form-input
              :class="{ 'border-danger': $v.form.name.$error }"
              id="input-0"
              v-model="form.name"
              type="text"
              required
              placeholder="Введите ФИО для доставки"/>
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group
                label="Email адрес:*"
                label-for="input-1">
              <b-form-input
                  :class="{ 'border-danger': $v.form.email.$error }"
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  required
                  placeholder="Введите email"/>
            </b-form-group>
          </b-col>
          <b-col v-show="!getAuthUser.id">
            <b-form-group
                label="Пароль для личного кабинета:*"
                label-for="input-11">
              <b-form-input
                  :class="{ 'border-danger': $v.form.password.$error }"
                  id="input-11"
                  v-model="form.password"
                  type="text"
                  required
                  placeholder="Введите пароль"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
            label="Телефон:*"
            label-for="input-2">
          <b-form-input
              :class="{ 'border-danger': $v.form.phone.$error }"
              id="input-2"
              v-model="form.phone"
              type="text"
              required
              placeholder="Введите телефон"/>
        </b-form-group>
        <b-form-group
            label="Адрес доставки:*"
            label-for="input-3">
          <b-form-input
              :class="{ 'border-danger': $v.form.address.$error }"
              id="input-3"
              v-model="form.address"
              type="text"
              required
              placeholder="Введите полный адрес"/>
        </b-form-group>
        <b-form-group
            label="Комментарий к заказу:"
            label-for="input-4">
          <b-form-textarea
              :class="{ 'border-danger': $v.form.comment.$error }"
              id="input-4"
              v-model="form.comment"
              rows="3"
              max-rows="6"/>
        </b-form-group>
      </div>
      <div class="text-right">
        <b-button variant="primary" v-if="!order_tab" @click="order_tab = true">Оформить заказ</b-button>
        <b-button variant="primary" v-if="!order_tab" @click="payment = true; order_tab = true" class="ml-3">
          Оформить и оплатить заказ
        </b-button>
        <b-button variant="dark" v-if="order_tab" @click="order_tab = false" class="mr-4">Назад</b-button>
        <b-button variant="primary" v-if="order_tab" @click="onSubmit">Отправить</b-button>

        <b-alert show v-if="!order_tab" variant="info" class="mt-3">
          Оплатить заказ можно как сразу так и позже в личном кабинете в разделе "Заказы"
        </b-alert>
      </div>
    </div>
    <div class="text-center" v-if="!getCart.length">Корзина пуста</div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {required, email} from 'vuelidate/lib/validators'

export default {
  name: 'Cart',
  data() {
    return {
      order_tab: false,
      payment: false,
      form: {
        name: null,
        email: null,
        phone: null,
        address: null,
        comment: null,
        password: null,
      }
    }
  },
  mounted() {
    this.queryProfile()
    if (this.getAuthUser.id) {
      this.form.email = this.getAuthUser.email
      this.form.phone = this.getAuthUser.phone
    }
  },
  validations() {
    if (!this.getAuthUser.id) {
      return {
        form: {
          name: {required},
          email: {required, email},
          phone: {required},
          address: {required},
          comment: {},
          password: {required},
        }
      }
    } else {
      return {
        form: {
          name: {required},
          email: {required, email},
          phone: {required},
          address: {required},
          comment: {},
          password: {},
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getAuthUser',
      'getCart',
      'getCartTotal',
      'getDelivery',
    ])
  },
  methods: {
    ...mapMutations([
      'changeCartCount',
      'removeFromCart',
    ]),
    ...mapActions([
      'queryProfile',
      'queryCreateOrder',
      'queryCreateOrderPayment',
      'setToast',
    ]),
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.payment) {
          this.queryCreateOrderPayment(this.form)
        } else {
          this.queryCreateOrder(this.form)
        }
      }
    }
  }
}
</script>

<style lang="scss">

</style>